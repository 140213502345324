.link, .active {
    background: transparent;
    border-radius: 0.625rem;
    border-width: 0;
    box-shadow: none;
    color: #122E73;
    cursor: pointer;
    display: inline-block;
    height: 1.25rem;
    font-family: Roboto, sans-serif;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.25rem;
    padding: 0.3125rem 0.625rem;
    text-decoration: none;
}

.active, .link:focus {
    background-color: #C4DEF5;
    color: #122E73;
    outline: none;
}
