.svg-canvas-container {
    background-color: inherit;
    border-radius: 0.625rem;
    grid-row: canvas;
    height: 100%;
    margin: 0 0.625rem 0;
    overflow: hidden;
    outline: none;
    position: relative;
    user-select: none;
    z-index: 100;
}

.svg-canvas {
    background-color: #FAFEFF;
    border-radius: 0.625rem;
    outline: none;
}

.figure, .text {
    opacity: 1;
}

g.figure:hover, .text:hover {
    opacity: 0.7;
}

.group:hover .figure {
    opacity: 0.7;
}

.figure-neighborhood:hover+.figure {
    opacity: 0.7;
}


.emoji-container {
    align-items: flex-end;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    height: 100%;
    pointer-events: none;
}

@keyframes highlight {
    0%   {stroke-width: 0.25rem; stroke: yellow;}
    25%  {stroke-width: 0.5rem; stroke: orange;}
    50%  {stroke-width: 0.75rem; stroke: red;}
    100% {stroke-width: 0.25rem; stroke: yellow;}
}

.blue-fill {
    /* fill: #82c4ec; */
}

.no-fill {
    fill: none !important;
}

.white-fill {
    fill: #fff !important;
}

.weak-stroke {
    stroke-linejoin: round;
    stroke-width: 1.33px;
}

.normal-stroke {
    stroke-width: 2px;
    stroke-linejoin: round;
}

.strong-stroke {
    stroke-width: 3px;
    stroke-linejoin: round;
}

.empty {
    /* fill: red; */
}

/* scroll */

.view {
    background-color: transparent;
}

.track-horizontal {
    background-color: transparent;
    left: 0.625rem;
    right: 0.625rem;
    bottom: 0.625rem;
}

.track-vertical {
    background-color: transparent;
    top: 0.625rem;
    bottom: 0.625rem;
    height: 95%;
    right: 0.625rem;
}

.thumb-horizontal {
    background-color: #C4DEF5;
    border-radius: 0.3125rem;
}

.thumb-vertical {
    background-color: #C4DEF5;
    border-radius: 0.3125rem;
}

.hint-connector {
    pointer-events: none;
}

.symbol-text, .symbol-emojis {
    font-weight: 600;
    stroke-width: 0.2mm;
    font-family: AnonymousPro, sans-serif;
    user-select: none;
}


/* flags */

.point-feature-flag-pink {
    cursor: pointer;
    fill: pink;
    stroke: pink;
}

.point-feature-flag-red {
    cursor: pointer;
    fill: red;
    stroke: red;
}

.point-feature-flag-green {
    cursor: pointer;
    fill: green;
    stroke: green;
}

.point-feature-flag-blue {
    cursor: pointer;
    fill: blue;
    stroke: blue;
}

.point-feature-flag-black {
    cursor: pointer;
    fill: black;
    stroke: black;
}

.point-feature-cross {
    cursor: pointer;
    fill: #144066;
}

.point-feature-cross:hover {
    fill: #FF983D;
}

.canvas-to-center-wrapper, .canvas-show-grid-wrapper, .canvas-background-color-wrapper {
    margin-top: 0.125rem;
    position: fixed;
}

.canvas-to-center-wrapper {
    right: 1rem;
}

.canvas-show-grid-wrapper {
    right: 3rem;
}

.canvas-background-color-wrapper {
    align-items: flex-end;
    display: flex;
    flex-direction: column;
    right: 4.575rem;
}

.canvas-hud-button {
    background-color: #C4DEF5;
    border: none;
    border-radius: 25%;
    color: #144066;
    font-size: 1.25rem;
    line-height: 1rem;
    padding: 0.25rem 0.25rem;
}

.canvas-hud-button:hover {
    background-color: #144066;
    color: #FAFEFF;
}

.worldmap__figure-container {
    margin: 0;
}

.worldmap__figure-container path {
    fill-opacity: 1 !important;
}
