
.custom-drag-layer-container {
    position: fixed;
    pointer-events: none;
    z-index: 100;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
}

.custom-drag-layer-preview {
    align-items: center;
    cursor: move;
    display: flex;
    pointer-events: none;
}

.custom-drag-layer-preview-wrapper {
    display: inline-block;
}

.custom-drag-layer-preview-icon-wrapper {
    margin: 0 0.25rem;
}

.custom-drag-layer-preview-etc {
    font-size: 1.5rem;
}