.logo-text {
    width: 12.0625rem;
    height: 100%;
    margin: 0 0 0 1.25rem;
}

.logo-text::before {
    content: "Creative Maps Store —";
    color: #C4DEF5;
    font-family: Rubik, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 1.125rem;
    line-height: 1.3125rem;
}
