.store-container {
    height: calc(100vh - 3.75rem);
    overflow: auto;
    width: 100%;
    margin: 3.75rem 0 0 0;
}

.react-select-clear-indicator-wrapper {
    align-items: center;
    display: flex;
    justify-content: center;
    height: 1.5rem;
    width: 1.5rem;
}

.store-button {
    background: #144066;
    border: none;
    border-radius: 0.625rem;
    color: #FAFEFF;
    cursor: pointer;
    font-family: Rubik, sans-serif;
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 400;
    height: 2.75rem;
    margin-bottom: 0.375rem;
    text-transform: uppercase;
    width: 100%;
}

.store-button:focus {
    outline: none;
}

.store-product-preview {
    border-radius: 3%;
    background-size: cover;
    display: flex;
}

.store-select {
    color: #144066;
    font-family: Roboto, sans-serif;
}

.store-select div {
    color: inherit;
    padding: 0;
}

.store-select div[class$="-control"] {
    background-color: transparent;
    background-repeat: repeat;
    border: 0.125rem solid #C4DEF5;
    border-radius: 0.625rem;
    box-sizing: border-box;
    box-shadow: none;
    color: #144066;
    font-family: Roboto, sans-serif;
    font-size: 0.875rem;
    line-height: 1.5rem;
    min-height: auto;
    padding: 0.25rem 0 0.25rem 0.625rem;
    width: 100%;
}

.store-select div[class*="Input"] {
    grid-area: 1/3/2/3;
    margin: 0 0 0 0.125rem;
}

.store-select div[class*="ValueContainer"] {
    flex-wrap: nowrap;
}

.store-select div[class*="input-container"] {
    grid-area: 1/3/2/3;
}

.store-select div[class*="control--is-focused"] {
    background-color: #C4DEF5;
}

.store-select div[class$="menu"] {
    background-color: #FAFEFF;
    border: 0.125rem solid #C4DEF5;
    border-radius: 0.625rem;
    line-height: 2rem;
    min-height: 2rem;
}

.store-select div[class*="menu-list"] {
    background-color: #FAFEFF;
    border-radius: 0.625rem;
    margin: 0;
}

.store-select div[class*="placeholder"] {
    opacity: 0.6;
}

.store-select div[class*="value-container--has-value"] {
    flex-wrap: nowrap;
    opacity: 1;
}

.store-select div[class*="option"] {
    color: #144066;
    font-family: Roboto, sans-serif;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5rem;
    background-color: #FAFEFF;
    padding: 0.3125rem 0.625rem;
}

.store-select div[class*="option"]:hover {
    background-color: #C4DEF5;
}

.store-select div[class*="option"]:first-child {
    padding-top: 0.625rem;
}

.store-select div[class*="option"]:last-child {
    padding-bottom: 0.625rem;
}

.store-select div[class*="clear-indicator"] {
    margin-right: 0.5rem;
}
