.product-body {
    background-color: #FAFEFF;
    color: #122E73;
    display: flex;
    flex-direction: column;
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.25rem;
    padding: 0;
    width: 100%;
}

.product-title-container {
    align-items: baseline;
    background-color: #FAFEFF;
    display: flex;
    flex-direction: row;
    margin: 0;
    padding: 1.875rem 5.625rem 1.25rem;
    position: fixed;
    width: 100%;
}

.product-title-category {
    cursor: pointer;
    font-weight: 300;
    user-select: none;
}

.product-title-name {
    color: #144066;
    font-family: Rubik, sans-serif;
    font-weight: 500;
    font-style: normal;
    font-size: 1.5rem;
    line-height: 1.75rem;
    margin-left: 0.2rem;
}

.product-wrapper {
    display: flex;
    margin: 0 5.625rem 5.875rem;
}

.product-brief-container {
    align-items: center;
    display: flex;
    flex-direction: column;
    margin-top: 4.875rem;
    position: fixed;
}

.product-brief-container label {
    margin-bottom: 1rem;
}

.product-brief-price-container {
    color: #0F1D23;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 0.625rem 0 1.25rem;
    width: 100%;
}

.product {
    margin-top: 4.875rem;
    margin-left: 21.25rem;
    width: 100%;
    display: grid;
    grid-template-columns: 10rem 15rem 10rem auto;
    row-gap: 1.25rem;
    grid-template-rows: 1.5rem 1.5rem 1.5rem 1.5rem auto 2rem;
    font-size: initial;
}

.product-wrapper label:not(.product-version-label) {
    color: #0F1D23;
}

.product span {
    color: #144066;
    white-space: nowrap;
    overflow: hidden;
}

.product-extra-info-tab-container {
    display: flex;
    grid-column-start: 1;
    grid-column-end: 5;
    width: 40rem;
    justify-content: flex-start;
}

.product-extra-info-tab-container div {
    margin-right: 1.25rem;
}

.product-extra-info-container {
    grid-column-start: 1;
    grid-column-end: 5;
    width: 100%;
}

.product-new-comment-title {
    color: #144066;
    display: block;
    font-family: Roboto, sans-serif;
    font-size: 1.125rem;
    font-weight: 500;
    line-height: 1.5rem;
    margin: 3rem 0 1.25rem;
}

.product-md-editor {
    align-items: center;
    border: 0.125rem solid #C4DEF5;
    border-radius: 0.625rem;
    display: flex;
    flex-direction: column;
}

.product-add-comment-wrapper {
    margin-top: 0.75rem;
    width: 14.375rem
}

.product-raster-preview-container {
    display: flex;
    flex-wrap: wrap;
}

.product-raster-preview-icon {
    margin: 0 1.25rem 1.25rem 0;
    height: 2.5rem;
    width: 2.5rem;
}

.product-clickable {
    cursor: pointer;
}

/*version tabs*/

.product-version-review {
    display: none;
}

.product-version-input {
    display: none;
}

.product-version-label {
    color: #122E73;
    cursor: pointer;
    display: block;
    font-family: Rubik, sans-serif;
    font-weight: 500;
    font-style: normal;
    font-size: 1.25rem;
    line-height: 1.5rem;
    margin-left: 2rem;
    margin-top: 1rem;
    user-select: none;
}

.product-version-input:checked+label+.product-version-review {
    display: block;
    margin-top: 1rem;
}

.product-version-link {
    cursor: pointer;
    display: block;
    margin-bottom: 1rem;
    text-decoration: underline;
}

.product-license-container {
    margin: 0;
}

.product-license-container ol {
    list-style-type: lower-latin;
}

.product-license-container ol ol {
    list-style-type: decimal;
}

.product-license-container ol ol ol {
    list-style-type: upper-latin;
}

.product-license-container ol ol ol ol {
    list-style-type: lower-roman;
}

.product-set-rating-wrapper {
    margin-top: 1rem;
}

.product-description {
    grid-column-start: 2;
    grid-column-end: 5;
}

.product-preview-container {
    border-radius: 3%;
    background-size: cover;
    display: flex;
}