.product-form-container {
    background-color: #FAFEFF;
    display: grid;
    grid-row-gap: 0.625rem;
    grid-template-columns: 10rem 1fr;
    padding: 1.875rem 0 5.625rem 5.625rem;
}

.product-form-container input {
    line-height: normal;
}

.product-form-container h1:not(.wmde-markdown h1) {
    color: #144066;
    font-family: Rubik, sans-serif;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.75rem;
    grid-column-end: 3;
    grid-column-start: 1;
    margin: 0 0 0.5rem 0;
}

.product-form-container span.product-form-field-name {
    color: #0F1D23;
    font-family: Roboto, sans-serif;
    font-size: 1rem;
    font-style: normal;
    font-weight: 300;
    line-height: 1.5rem;
    margin-top: 0.5rem;
}

.product-form-add-field-button {
    fill: #144066;
    margin-right: 0.625rem;
    transform: rotate(45deg);
}

.product-form-delete-field-button {
    fill: #144066;
    margin-right: 0.625rem;
}

.product-form-add-field-button:hover, .product-form-delete-field-button:hover {
    fill: #FF983D;
}

.product-from-icon-search, .product-from-icon-cross {
    fill: #144066;
    margin-right: 0.625rem;
    stroke: #144066;
}

.product-from-icon-cross {
    opacity: 1;
    transform: scale(0.75);
}

.product-form-clear-indicator {
    fill: #144066;
    stroke: #144066;
    opacity: 1;
    transform: scale(0.75);
}

.product-form-lang-selector-item-container {
    background-color: #FAFEFF;
    display: flex;
    flex-direction: column;
    margin: 0 0 0 -0.3rem;
    padding: 0.5rem 0 0 0;
    position: absolute;
    user-select: none;
    z-index: 200;
}

.product-form-lang-selector-item {
    color: #144066;
    font-size: 1rem;
    line-height: 1.3rem;
    list-style-type: none;
    margin: 0.2rem 0;
    padding: 0;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
    width: 1.6rem;
}

.product-form-lang-selector-item:hover {
    font-weight: 900;
}

.product-form-field-container {
    align-items: center;
    display: flex;
}

.product-form-input-wrapper {
    margin-right: 0.625rem;
    width: 18.75rem;
}

.simple-tool-wrapper {
    background: #FAFEFF;
    border: 0.125rem solid #FAFEFF;
    border-radius: 0.625rem;
    height: 2.8125rem;
    margin: 0 0.03125rem 0.1875rem;
    padding-top: 0.3125rem;
    width: 3.125rem;
}

.simple-tool-active-wrapper {
    background: #FAFEFF;
    border: 0.125rem solid #FF983D;
    border-radius: 0.625rem;
    height: 2.8125rem;
    margin: 0 0.03125rem 0.1875rem;
    padding-top: 0.3125rem;
    width: 3.125rem;
}

/*** markdown styles start ***/

.product-form-editor {
    align-items: center;
    border: 0.125rem solid #C4DEF5;
    border-radius: 0.625rem;
    display: flex;
    flex-direction: column;
    margin-right: 0.625rem;
    width: 63.75rem;
}

.w-md-editor-toolbar {
    align-items: center;
    background-color: #FAFEFF;
    border-bottom: 0.0625rem solid #C4DEF5;
    display: flex;
    /*padding: 0.3125rem 0;*/
    padding: 0;
    width: 98%;
}

.w-md-editor-content {
    width: 100%;
}

.w-md-editor-toolbar ul {
    height: 1.75rem;
}

.w-md-editor-toolbar li {
    height: 1.4375rem;
    margin: 0;
    padding: 0.0625rem 0.3125rem;
}

.w-md-editor-toolbar li button {
    color: #144066;
    height: 1.4375rem;
    margin: 0;
    padding: 0;
    width: 1.25rem;
}

.w-md-editor-toolbar li button:hover {
    background-color: #C4DEF5;
    border: none;
    border-radius: 0.375rem;
}

.w-md-editor-toolbar li > button:focus {
    background-color: #C4DEF5;
    border: none;
    border-radius: 0.375rem;
}

.w-md-editor-toolbar li.active button {
    background-color: #C4DEF5;
}

.w-md-editor-text-input {
    -webkit-text-fill-color: #144066;
}

.w-md-editor-text-pre {
    visibility: hidden;
}

.w-md-editor-bar {
    bottom: 0;
}

.w-md-editor-bar svg {
    opacity: 0;
}

.w-md-editor-bar::before {
    color: #C4DEF5;
    content: "/";
    display: inline-block;
    font-weight: 900;
    height: 1rem;
    transform: rotate(45deg) scale(1.8, 1.5);
    width: 1rem;
}

.md-editor-divider {
    margin: 0 0.625rem;
}

.md-editor-heading {
    align-items: center;
    border: 0.1rem solid #144066;
    border-radius: 0.375rem;
    display: flex;
    justify-content: space-between;
    height: 1.6125rem;
    margin: 0;
    top: -0.25rem;
    position: relative;
    width: 7.8rem;
}

button:hover .md-editor-heading {
    background-color: #C4DEF5;
}

.md-editor-heading:hover {
    background-color: #C4DEF5;
}

.md-editor-heading p {
    font-size: 1rem;
    line-height: 2rem;
    margin: 0;
    position: relative;
    left: 0.4rem;
}

.wmde-markdown {
    background-color: #FAFEFF;
    color: #144066;
    font-family: Roboto, sans-serif;
}

.product-form-editor-wrapper ul svg {
}

/*** markdown styles end ***/

.product-form-add-file-link {
    color: #122E73;
    cursor: pointer;
    display: inline-block;
    font-family: Roboto, sans-serif;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.25rem;
    margin: 0.5rem 0;
}

.product-form-add-file-link:hover {
    font-weight: 500;
}

/*** archive styles start ***/

.product-form-archive-container {
    border: 0.125rem dashed #143D66;
    border-radius: 0.625rem;
    margin-right: 0.625rem;
    outline: none;
    padding: 1rem 2rem;
    width: fit-content;
    width: -moz-fit-content;
}

.product-form-archive-container:hover label {
    font-weight: 500;
}

.product-form-archive-wrapper {
    outline: none;
    width: fit-content;
    width: -moz-fit-content;
}

.product-form-archive {
    background-image: url(../../images/archive-folder.svg);
    background-repeat: no-repeat;
    background-size: contain;
    border-radius: 0.625rem;
    display: flex;
    height: 7rem;
    margin-right: 1.25rem;
    outline: none;
    width: 10rem;
}

.product-form-archive:hover div {
    background-color: rgba(20, 61, 102, 0.4);
    opacity: 1;
}

.product-form-archive-button-replace {
    background-color: #143D66;
    background-image: url(../../images/reload-white.svg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: auto;
    border-bottom-left-radius: 0.625rem;
    border-top-left-radius: 0.625rem;
    height: 100%;
    opacity: 0;
    width: 50%;
}

.product-form-archive-button-remove {
    background-image: url(../../images/cross-white.svg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: auto;
    border-bottom-right-radius: 0.625rem;
    border-top-right-radius: 0.625rem;
    height: 100%;
    opacity: 0;
    width: 50%;
}

/*** archive styles end ***/

.product-form-radio-item {
    color: #122E73;
    display: flex;
    font-family: Roboto, sans-serif;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5rem;
    margin: 0 1rem 0 0.5rem;
}

.product-form-radio-item-hint {
    border: 0.12rem solid #144066;
    border-radius: 50%;
    display: inline;
    font-family: Roboto, sans-serif;
    font-size: 0.75rem;
    font-weight: 700;
    height: 0.7rem;
    line-height: 0.7rem;
    margin: 0.2rem 0 0 0.3rem;
    padding: 0.18rem 0.28rem 0;
    user-select: none;
}

.product-form-agreement-container {
    align-items: baseline;
    color: #144066;
    display: flex;
    font-family: Roboto, sans-serif;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.25rem;
}

.product-form-agreement-container>a {
    color: #144066;
}

.product-form-price-field::-webkit-outer-spin-button,
.product-form-price-field::-webkit-inner-spin-button { /* For Chrome, Safari, Edge, Opera */
    -webkit-appearance: none;
    margin: 0;
}

.product-form-price-field {
    -moz-appearance: textfield; /* For FF */
}

.product-form-icons-container {
    display: grid;
    grid-row-gap: 1.25rem;
    grid-template-columns: repeat(16, 1fr);
    width: 58.73rem;
}

.errorMessage {
    font-family: Roboto, sans-serif;
    color: #FE4B5F;
    font-size: .9rem;
}

.product-form-submit-container {
    grid-column-start: 1;
    grid-column-end: 3;
}

.product-form-submit-container>button {
    width: 20.3125rem;
}

.product-form-tooltip {
    animation-duration: 1s;
    animation-fill-mode: forwards;
    animation-name: show;
    background: #C4DEF5;
    border: 0.1rem solid #144066;
    border-radius: 0.625rem;
    color: #144066;
    display: block;
    max-width: 21.875rem;
    min-width: 10rem;
    padding: 0.625rem;
    pointer-events: none;
    white-space: normal;
    z-index: 1200;
}

.react-tooltip-lite {
    pointer-events: none;
}

.product-form-licenses-link {
    color: #144066;
    cursor: pointer;
    font-family: Roboto, sans-serif;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.25rem;
    margin-right: 0.5rem;
    text-decoration: underline;
}

.product-form-card-photo-wrapper {
    border: 0.125rem solid #C4DEF5;
    border-radius: 0.625rem;
    height: 75px;
    padding: 0.5rem;
    width: 115px;
}

.product-form-maps-container {
    display: flex;
    flex-wrap: wrap;
    width: 63.75rem;
}

.product-form-map {
    border: 0.1rem solid #144066;
    border-radius: 0.625rem;
    display: flex;
    height: 5rem;
    justify-content: center;
    margin: 0.5rem;
    width: 7.5rem;
}