.top-block {
    grid-row: menu;
    display: flex;
}

.top-menu-container {
    display: flex;
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 1.125rem;
    list-style-type: none;
    margin: 0;
    padding-left: 0;
    user-select: none;
}

.top-menu-container>li {
    padding: 0.5625rem 1.25rem;
}

.top-menu-progress-bar {
    background-color: #9CC6EA;
    color: #144066;
    padding: 0.3125rem 0;
    font-size: 0.875rem;
}

.top-menu-pseudo-title, .top-menu-pseudo-title-rounded {
    background-color: #C4DEF5;
    border: 0.0625rem solid #144066;
    padding: 0.5rem 1.1875rem 0.3125rem;
    line-height: 1.625rem;
    font-weight: 600;
    position: absolute;
    pointer-events: none;
    z-index: 300;
}

.top-menu-pseudo-title {
    border-top-left-radius: 0.625rem;
    border-top-right-radius: 0.625rem;
    border-bottom: 0.0625rem solid #C4DEF5;
}

.top-menu-pseudo-title-rounded {
    border-radius: 0.625rem;
}

.top-menu-item {
    background-color: #C4DEF5;
    border: 0.0625rem solid #144066;
    border-radius: 0.625rem;
    border-top-left-radius: 0;
    display: flex;
    flex-direction: column;
    max-width: 20rem;
    min-width: 7.5rem;
    position: absolute;
    z-index: 200;
}

.top-menu-subitem, .top-menu-hot-key, .top-menu-subitem-disabled, .top-menu-hot-key-disabled {
    padding: 0.875rem;
    margin: 0;
    font-size: 1rem;
    opacity: 0.8;
}

.top-menu-subitem, .top-menu-hot-key {
    opacity: 0.8;
}

.top-menu-subitem-disabled, .top-menu-hot-key-disabled {
    opacity: 0.3;
    pointer-events: none;
}

.top-menu-subitem:hover {
    font-weight: 600;
    opacity: 1;
}

.top-menu-subitem-container {
    display: flex;
    justify-content: space-between;
}

.map-title-wrapper {
    cursor: pointer;
    flex-grow: 1;
    text-align: center;
}

.map-title-wrapper>* {
    font-family: Rubik, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 1.25rem;
    line-height: 1.5rem;
}

.map-title-header {
    margin: 0.5rem 0 0;
}

.map-title-input {
    background-color: #C4DEF5;
    border: none;
    border-radius: 0.375rem;
    color: #144066;
    outline: none;
    margin: 0.375rem 0 0;
    max-width: 30rem;
    text-align: center;
}

.top-menu-auth-button {
    color: #122E73;
    cursor: pointer;
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 1.125rem;
    line-height: 1.375rem;
    margin: 0.5625rem 0;
    padding: 0 1.25rem;
}

.top-menu-auth-button:hover {
    font-weight: 600;
}

.top-menu-auth-button+.top-menu-auth-button {
    padding-left: 0;
}

.top-menu-auth-button+.top-menu-auth-button::before {
    content: "|";
    display: inline-block;
    font-size: 1.25rem;
    line-height: 0.875rem;
    margin-right: 1.25rem;
    opacity: 0.7;
    transform: scaleX(0.5);
}

.top-menu-user-name-container {
    align-items: center;
    cursor: pointer;
    display: flex;
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 1.125rem;
    justify-content: flex-end;
    line-height: 1.1875rem;
}

.top-menu-user-name-container span:hover {
    font-weight: 500;
}


.top-menu-avatar-wrapper {
    align-items: center;
    display: flex;
    margin-left: 0.625rem;
}
