.floating-menu-emoji-picker, .floating-menu-emoji-container {
    background: none;
    border-radius: inherit;
    width: 14.6875rem;
}

.floating-menu-emoji-container {
    padding-left: 0.25rem;
    padding-top: 0.25rem;
}

.emoji-mart {
    background: none;
    border: none;
    width: 100% !important;
}

.emoji-mart-scroll {
    max-height: 12.5rem;
}

.emoji-mart-category-label>span {
    background: #C4DEF5;
    color: #144066;
}

.emoji-mart-search {
    display: none;
}

.emoji-mart-bar {
    border: none;
}

.emoji-mart-anchor {
    padding: 0.5rem 0;
}

.emoji-mart-anchor-icon svg {
    transform: scale(0.8);
}

.emoji-mart-anchor-bar {
    background-color: #144066 !important;
}
