.name-container {
    background: none;
    cursor: pointer;
    display: flex;
    height: auto;
    margin: 0 0 0 1.2rem;
    text-decoration: none;
    width: 23.2rem;
}

.name-studio {
    color: #C4DEF5;
    font-family: Rubik, sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 1.25rem;
    line-height: 1.5rem;
}

.name-semantics {
    color: #C4DEF5;
    font-family: Rubik, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 1rem;
    line-height: 1.7rem;
    margin-left: 0.2rem;
}

.auth-button-container {
    display: flex;
}

.sign-up, .sign-in {
    color: #C4DEF5;
    cursor: pointer;
    font-family: Roboto, sans-serif;
    font-size: 1rem;
    line-height: 1.25rem;
}

.sign-up:hover, .sign-in:hover {
    font-weight: 500;
}

.separator {
    color: #C4DEF5;
    cursor: default;
    font-family: Roboto, sans-serif;
    font-size: 1rem;
    line-height: 1.25rem;
    margin: 1rem 0.9rem;
}
