.project-group-table {
    border-spacing: 0;
    color: #144066;
}

.project-group-table tr:nth-child(2n) {
    background-color: #c4def577;
}

.project-group-table td {
    padding: 0.5rem 0.5rem;
}

.project-group-table td:first-child {
    max-width: clamp(20vw, 30rem, 40vw);
}

.my-projects-create-project-btn {
    border: none;
    border-radius: 0.5rem;
    background-color: #144066;
    color: #FAFEFF;
    font-family: Roboto, sans-serif;
    font-size: 1rem;
    font-weight: 500;
    padding: 0.5rem 1rem;
    text-transform: uppercase;
}

.my-projects-create-project-btn:hover {
    background-color: #FAFEFF;
    color: #144066;
}

.my-projects-create-project-btn:active {
    color: #9cc6ea;
}