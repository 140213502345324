$white: #FAFEFF;
$light-blue: #C4DEF5;
$blue: #9CC6EA;
$dark: #144066;
$radius: 0.625rem;
$small-indent: 0.375rem;
$standard-indent: 0.625rem;

@mixin standard-textfield($width: 100%) {
    background-color: $blue;
    border: none;
    border-radius: $radius;
    color: $dark;
    line-height: 1.25rem;
    font-size: 1rem;
    padding: $standard-indent 2.5rem;

    &::placeholder {
        color: transparentize($dark, 0.4);
    }
}

.chat {
    background-color: $blue;
    border-radius: $radius;
    bottom: 3rem;
    display: flex;
    flex-direction: column;
    height: 35rem;
    justify-content: space-between;
    position: absolute;
    right: 1.25rem;
    width: 25rem;
    z-index: 100;

    .tabs-container {
        height: 1.75rem;
        margin: $standard-indent $standard-indent 0;

        .tab, .tab-active {
            border-radius: $radius $radius 0 0;
            padding: $small-indent $standard-indent;
        }

        .tab-active {
            background-color: $light-blue;
        }
    }

    .messages-wrapper {
        background-color: $light-blue;
        border-radius: $radius;
        border-top-left-radius: 0;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        height: calc(100% - #{3 * $standard-indent});
        margin: 0 $standard-indent $standard-indent;
        padding: $standard-indent;

        .messages {
            display: flex;
            flex-direction: column;
            min-height: calc(100% - #{$standard-indent} - 2.5rem);
            margin-bottom: $standard-indent;

            .date-header {
                color: transparentize($dark, 0.3);
                font-size: 0.875rem;
                margin: $standard-indent 0;
                text-align: center;
                width: 100%;
            }

            .message-container {
                align-items: flex-end;
                display: flex;

                .message-avatar {
                    * {
                        border-radius: 0.375rem;
                    }
                }

                .rce-container-mbox {
                    overflow: initial;

                    .rce-mbox{
                        border: none;
                        box-shadow: none;
                        padding: $standard-indent;
                        margin: 0.125rem $small-indent;

                        .rce-mbox-time {
                            color: transparentize($dark, 0.3);
                        }
                    }
                }
            }
        }

        .current-message-field {
            @include standard-textfield();
        }
    }
}

.lower,
.upper,
.separator {
    width: 100%;
    height: 0;
}

.separator,
.lower {
    margin-top: auto;
}

.placeholder {
    height: 50rem;
}
