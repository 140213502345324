$light-blue: #C4DEF5;

.chat-icon-wrapper {
    background-color: $light-blue;
    border-radius: 0.375rem;
    height: 1.375rem;
    margin-right: 0.625rem;
    padding: 0.25rem;
    width: 1.375rem;
}
