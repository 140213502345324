.name {
    margin: 0 0 0 1rem;
    height: initial;
    width: 15rem;
}

.title {
    display: flex;
    min-width: 22rem;
    height: 100%;
    margin: 0 4rem 0 5.6rem;
    align-items: center;
    overflow: hidden;
}

.icon {
    display: flex;
    align-self: center;
    height: 2.3rem;
    padding-right: 2.7rem;
}

.icons {
    align-items: center;
    display: flex;
    min-width: 17.3rem;
    height: 2.3rem;
    justify-content: flex-end;
    padding-right: 6.625rem;
}

.header {
    display: flex;
    position: fixed;
    justify-content: space-between;
    background-repeat: no-repeat;
    align-items: center;
    background-color: #144066;
    width: 100%;
    height: 3.75rem;
    top: 0;
    user-select: none;
    z-index: 10;
}

.logo {
    width: 2.5rem;
    height: 2.5rem;
}

.icons {
    align-items: center;
    display: flex;
    min-width: 17.3rem;
    height: 2.3rem;
    justify-content: flex-end;
    padding-right: 6.625rem;
}

.icons div {
    align-self: auto;
    display: flex;
    padding-right: 0;
    height: auto;
    width: auto;
}

.icons div[class="store-dropbtn"] {
    padding: 10px 10px 0px 10px;
    font-size: 16px;
    border: none;
}

/* The container <div> - needed to position the dropdown content */
.icons div[class="store-dropdown"] {
    position: relative;
    display: inline-block;
}

/*based on*/
/*https://www.w3schools.com/howto/howto_css_dropdown.asp*/
/* Dropdown Button */
.icons div[class="store-dropbtn"] {
    padding: 0.9375rem 0.625rem;
    font-size: 1rem;
    border: none;

    display: inline-block;
    padding: 0.625rem 0;
    position: relative;

    color: #C4DEF5;
}

/* Dropdown Content (Hidden by Default) */
.icons div[class="store-dropdown-content"] {
    display: none;
    position: absolute;
    background: none;
    z-index: 1;
}

/* Show the dropdown menu on hover */
.icons div[class="store-dropdown"]:hover .icons div[class="store-dropdown-content"] {
    display: block;
}

.icons div[class="store-user-name-container"] {
    font-family: Roboto, sans-serif;
    font-weight: 400;
    display: flex;
    align-items: center;
}

.icons div[class="store-dropdown"]:hover>.store-dropbtn>.store-user-name-container {
    font-weight: 500;
}

/* Change the background color of the dropdown button when the dropdown content is shown */
.icons div[class="store-dropdown"]:hover .icons div[class="store-dropbtn"] {
    background-color: #144066;
}
