form {
    display: grid;
    grid-template-columns: 1fr;
    row-gap: 0;
    align-items: center;
}

form > div {
    grid-column: 1;
}

form input, form textarea {
    background: #9CC6EA;
    border-radius: 0.625rem;
    font-weight: normal;
    font-style: normal;
}

form input {
    font-size: 1.125rem;
    line-height: 2.5rem;
}

form textarea {
    font-size: 1rem;
    line-height: 1.125rem;
}

form input:focus {
    background: white;
}

form button {
    background-color: #003F64;
    border: none;
    border-radius: 0.625rem;
    color: white;
    font-size: 1.25rem;
    height: 2.8125rem;
    margin-top: 0.5rem;
    outline: none;
    padding: 0.625rem 1.875rem;
    text-transform: uppercase;
    width: 100%;
}

form button:hover {
    background: none;
    color: #003F64;
    border: 0.125rem solid #003F64;
}

form button:active {
    background: #9CC6EA;
    border: 0.125rem solid #003F64;
    color: #003F64;
}

form .error {
    color: #FE4B5F;
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 0.75rem;
    line-height: 1.25rem;
    margin-left: 0.9375rem;
    margin-bottom: 0.25rem;
}

form ~ a {
    color: #122E73;
    display: block;
    margin-top: 1rem;
    text-align: center;
    text-decoration: none;
    width: 100%;
}

.social-signin {
    display: flex;
    justify-content: space-evenly;
}

.auth-social-icon {
    align-items: center;
    border: 0.125rem solid #144066;
    border-radius: 0.375rem;
    cursor: pointer;
    display: flex;
    height: 2.75rem;
    justify-content: center;
    width: 2.75rem;
}

.auth-title {
    color: #144066;
    font-family: Rubik, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 1.25rem;
    line-height: 1.5rem;
    margin-top: 0.75rem;
    margin-bottom: 1.25rem;
    text-align: center;
}


.auth-text-field {
    background: #9CC6EA;
    border: 0.0625rem solid #9CC6EA;
    border-radius: 0.625rem;
    color: #144066;
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 1.125rem;
    height: 1.5625rem;
    line-height: 1.5rem;
    margin-bottom: 1.5rem;
    margin-top: 0;
    outline: none;
    padding: 0.625rem 0.9375rem;
    width: 18.125rem; /*20 with paddings*/
}

.auth-text-field::placeholder {
    color: rgba(20, 64, 102, 0.6);
}

.auth-text-field:focus::placeholder {
    color: rgba(20, 64, 102, 0.6);
}

.auth-link, .auth-link-survey, .auth-text {
    color: #074479;
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 0.875rem;
    line-height: 1.5rem;
    margin: auto;
    text-decoration: none;
}

.auth-link {
    cursor: pointer;
    margin-bottom: 0.9375rem;
    margin-top: 0;
}

.auth-link-survey {
    cursor: pointer;
    margin-bottom: 0;
    margin-top: 0;
}

.auth-link:hover, .auth-signup-link:hover {
    font-weight: 600;
}

.auth-text {
    margin-bottom: 0.375rem;
    margin-top: 0.6875rem;
    user-select: none;
}

.field-wrapper {
    display: flex;
    flex-direction: column;
}

.recaptcha-container {
    border-radius: 1.25rem;
    margin-left: 0.5rem;
    margin-bottom: 1.5rem;
    width: 19rem;
}

.recaptcha-container iframe {
    border-radius: 1.25rem;
    border-style: none;
}


/*based on*/
/*https://www.w3schools.com/howto/howto_css_dropdown.asp*/
/* Dropdown Button */
.dropbtn {
    border: none;
    font-size: 1rem;
    padding: 0.625rem 0.625rem 0;
}

/* The container <div> - needed to position the dropdown content */
.dropdown {
    display: inline-block;
    position: relative;
    width: 8.5rem;
}

/* Dropdown Content (Hidden by Default) */
.dropdown-content {
    background-color: #f1f1f1;
    box-shadow: 0 0.5rem 1rem 0 rgba(0, 0, 0, 0.2);
    display: none;
    min-width: 100%;
    position: absolute;
    z-index: 1;
}

/* Links inside the dropdown */
.dropdown-content span {
    background-color: #BEDFF3;
    color: #122E73;
    cursor: pointer;
    display: block;
    padding: 0.75rem 1rem;
    text-decoration: none;
}

/* Change color of dropdown links on hover */
.dropdown-content span:hover {
    background-color: #90C7E8;
    font-weight: 600;
}

/* Show the dropdown menu on hover */
.dropdown:hover .dropdown-content {
    display: block;
}

/* Change the background color of the dropdown button when the dropdown content is shown */
.dropdown:hover .dropbtn {
    background-color: #BEDFF3;
}

/*based on*/
/*https://www.w3schools.com/howto/howto_css_dropdown.asp*/
/* Dropdown Button */
.store-dropbtn {
    border: none;
    color: #C4DEF5;
    display: inline-block;
    font-size: 1rem;
    padding: 0.625rem 0;
    position: relative;
}

/* Dropdown Content (Hidden by Default) */
.store-dropdown-content {
    background: none;
    display: none;
    position: absolute;
    z-index: 1;
}

/* Links inside the dropdown */
.store-dropdown-content span {
    background-color: #144066;
    border-bottom-left-radius: 0.625rem;
    border-bottom-right-radius: 0.625rem;
    color: #C4DEF5;
    cursor: pointer;
    display: block;
    font-family: Roboto, sans-serif;
    padding: 0.75rem 0.625rem;
    text-decoration: none;
}

/* Change color of dropdown links on hover */
.store-dropdown-content span:hover {
    font-weight: 500;
}

/* Show the dropdown menu on hover */
.store-dropdown:hover .store-dropdown-content {
    display: block;
}

.store-user-name-container {
    align-items: center;
    cursor: pointer;
    display: flex;
    font-family: Roboto, sans-serif;
    font-weight: 400;
}

.store-user-name-container span {
    color: #C4DEF5;
    margin-left: 0.625rem;
}

.store-dropdown:hover>.store-dropbtn>.store-user-name-container {
    font-weight: 500;
}

/* Change the background color of the dropdown button when the dropdown content is shown */
.store-dropdown:hover .store-dropbtn {
    background-color: #144066;
}


.edit-profile-form {
    display: grid;
    grid-template-areas:
        "title title title"
        "avatar full-name country"
        "avatar username region"
        "avatar interests settlement"
        "avatar email languages"
        "avatar birthdate gender"
        "apply apply .";
    grid-template-columns: 13.75rem 23.75rem 23.75rem;
    grid-template-rows: 3.125rem repeat(5, 6rem) 5rem;
}

.edit-profile-title {
    display: block;
    grid-area: title;
    margin: 0.125rem 0 1.5rem 0;
}

.edit-profile-avatar-container {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    grid-area: avatar;
    height: 100%;
    justify-content: flex-start;
    padding: 0 2.5rem 0 1.25rem;
}

.edit-profile-avatar-wrapper {
    height: 10rem;
    margin-bottom: 0.375rem;
    width: 10rem;
}

.edit-profile-avatar-container>span {
    color: #122E73;
    cursor: pointer;
    display: block;
    font-family: Roboto, sans-serif;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.375rem;
    margin-top: 0.625rem;
    overflow: hidden;
    width: 100%;
    word-wrap: break-word;
}

.edit-profile-avatar-container>span:hover {
    text-decoration: underline;
}

.edit-profile-full-name-container {
    grid-area: full-name;
    height: 100%;
}

.edit-profile-username-container {
    grid-area: username;
    height: 100%;
}

.edit-profile-interests-container {
    grid-area: interests;
    height: 100%;
}

.edit-profile-email-container {
    grid-area: email;
    height: 100%;
}

.edit-profile-country-container {
    grid-area: country;
    height: 100%;
}

.edit-profile-region-container {
    grid-area: region;
    height: 100%;
}

.edit-profile-settlement-container {
    grid-area: settlement;
    height: 100%;
}

.edit-profile-languages-container {
    grid-area: languages;
    height: 100%;
}

.edit-profile-birthdate-container {
    grid-area: birthdate;
    height: 100%;
}

.edit-profile-gender-container {
    grid-area: gender;
    height: 100%;
}

.edit-profile-form-field-name {
    color: #122E73;
    font-family: Roboto, sans-serif;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.25rem;
    margin-bottom: 0.3125rem;
}

.edit-profile-text-field, .edit-profile-text-field-short {
    background: #9CC6EA;
    border: 0.0625rem solid #9CC6EA;
    border-radius: 0.625rem;
    color: rgba(20, 64, 102, 1);
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 1rem;
    height: 1.25rem;
    line-height: 1.25rem;
    margin: 0;
    outline: none;
    padding: 0.625rem 1rem;
    max-width: 19.25rem; /*340 - padding*/
}

.edit-profile-text-field-short {
    max-width: 12.375rem; /*230 - padding*/
}

.edit-profile-apply-container {
    align-items: center;
    display: flex;
    grid-area: apply;
    justify-content: center;
}

.edit-profile-apply-wrapper {
    width: 12rem;
}

.buy-error-container {
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 12.5rem;
    justify-content: flex-start;
}

.buy-error-container p {
    color: #0F1D23;
    font-family: Roboto, sans-serif;
    font-size: 1rem;
    font-style: normal;
    font-weight: 300;
    line-height: 1.5rem;
    margin: 1.25rem 0 1rem;
    text-align: center;
    width: 28.75rem;
}

.buy-error-container button {
    background-color: #003F64;
    border: none;
    border-radius: 0.625rem;
    color: white;
    font-size: 1.25rem;
    height: 2.8125rem;
    margin-top: 1rem;
    outline: none;
    padding: 0.625rem 1.875rem;
    text-transform: uppercase;
    width: 13.375rem;
}

.buy-error-container button:hover {
    background: none;
    border: 0.125rem solid #003F64;
    color: #003F64;
}

.buy-error-container button:active {
    background: #9CC6EA;
    border: 0.125rem solid #003F64;
    color: #003F64;
}

.delete-profile-container {
    height: auto;
}
