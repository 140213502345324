.app {
    display: flex;
    flex-direction: column;
    background-color: #9CC6EA;
    justify-content: space-around;
    height: 100%;
}

.main-container {
    display: grid;
    grid-template-rows: [menu] 2.5rem [toolbar] 9.375rem [canvas] calc(100vh - 14.375rem) [social] 2.5rem;
    color: #144066;
}

.main-container-no-toolbar {
    display: grid;
    grid-template-rows: [menu] 2.5rem [canvas] calc(100vh - 5rem) [social] 2.5rem;
    color: #144066;
}

.disabled {
    opacity: 0.3;
    pointer-events: none;
}

.text-disabled {
    color: rgba(20, 64, 102, 0.3) !important; /* #144066 with 0.3 opacity */
    pointer-events: none;
}

.react-gravatar {
    border-radius: 0.375rem;
}

.long-polygon-configurator-container {
    background-color: #C4DEF5;
    border: 0.1rem solid #144066;
    border-radius: 0.625rem;
    display: flex;
    flex-direction: column;
    padding: 0.5rem 1rem;
}

.long-polygon-configurator-container p {
    color: #144066;
    font-size: 1.25rem;
    font-family: Roboto, sans-serif;
    margin: 0 0 0.75rem;
}

.long-polygon-configurator-container input[type="number"] {
    color: #144066;
    border: none;
    border-radius: 0.625rem;
    font-size: 1.25rem;
    font-family: Roboto, sans-serif;
    margin-bottom: 0.75rem;
    padding: 0.5rem 0.25rem;
}

.long-polygon-configurator-container input[type="button"] {
    color: #144066;
    background-color: #9CC6EA;
    border: none;
    border-radius: 0.625rem;
    font-size: 1.5rem;
    font-family: Roboto, sans-serif;
    margin-bottom: 0.75rem;
    padding: 0.5rem 0.25rem;
    text-transform: capitalize;
}

.long-polygon-configurator-container input[type="button"]:hover {
    background-color: #144066;
    color: #FAFEFF;
}

.tool-tabs-thumb-horizontal {
    /*transparent sliders*/
}