.share-container {
    display: flex;
    flex-direction: row;
}

.footer-icon-wrapper {
    background-color: #C4DEF5;
    border: 0.125rem solid #144066;
    border-radius: 0.375rem;
    height: 1.5rem;
    margin: 0 0.1875rem;
    padding: 0 0.1875rem 0.1875rem 0.1875rem;
    width: 1.5rem;
}

.footer-icon-wrapper:first-child {
    margin-left: 0;
}

.footer-icon-wrapper:hover {
    border: 0.125rem solid #C4DEF5;
}
