.filter {
    color: #0F1D23;
    display: grid;
    font-family: Roboto, sans-serif;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    grid-template-rows: 3.75rem 2rem 3.75rem 2rem auto 3.25rem auto 3.25rem 1.625rem;
    height: 18.75rem;
    line-height: 1.5rem;
    max-width: 16.94rem;
    min-width: 16.94rem;
    position: sticky;
    top: 0.75rem;
}

.filter label {
    color: #0F1D23;
    display: flex;
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.5rem;
    margin: 0;
}

.filter label.left-margin {
    margin-left: 1.875rem;
}

.filter label.filter-top-margin {
    margin-top: 1.25rem;
}

.filter-rating-container {
    align-items: center;
    display: flex;
    margin: 0;
}

.filter-rating-container label {
    margin-right: 0.5rem;
}

.filter-rating-clear-button {
    background: none;
    border: none;
    box-sizing: border-box;
    color: #144066;
    cursor: pointer;
    font-family: Roboto, sans-serif;
    font-size: 0.875rem;
    height: 1rem;
    line-height: 1rem;
    padding: 0 0 0 0.5rem;
}

.filter-price-container {
    align-items: baseline;
    display: flex;
    justify-content: space-around;
    width: 100%;
}

.filter-price-container input {
    width: 5rem;
}
