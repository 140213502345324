.project-main-container {
    background-color: #FAFEFF;
    height: 100%;
    min-height: 100%;
    overflow-y: auto;
}

.project-header {
    align-items: center;
    background-color: #C4DEF5;
    display: flex;
    height: 5rem;
    padding-left: 2rem;
    position: sticky;
    top: 0;
}

.project-header a {
    color: #144066;
}

.project-container {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    margin: 0 3rem 3rem;
}

.project-page-title {
    color: #144066;
    font-family: Roboto, sans-serif;
}

h1.project-page-title {
    margin: 0;
}

h2.project-page-title {
    margin-top: 3rem;
}

.project-title-container {
    align-items: center;
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: 2rem;
}

.project-link {
    color: #144066;
    cursor: pointer;
    font-family: Roboto, sans-serif;
    margin-right: 1rem;
    text-decoration: underline;
}

.project-link:hover {
    font-weight: 500;
}

.project-link:active {
    text-decoration: none;
}

.project-certain-content-container {
    margin-top: 2rem;
}

.project-p {
    color: #144066;
    margin: 0 0 1rem;
}

.project-link-p {
    font-size: 1.25rem;
    margin: 1rem 0 0;
}

.project-add-analysts {
    display: flex;
    flex-wrap: wrap;
    margin-top: 1rem;
}

.project-add-analysts :first-child {
    margin-left: 0;
}

.project-delete-btn {
    border: none;
    border-radius: 0.5rem;
    color: #FAFEFF;
    cursor: pointer;
    margin-top: 4rem;
    text-transform: uppercase;
    background-color: firebrick;
    padding: 0.5rem 1rem;
}

.project-delete-btn:hover {
    font-weight: 900;
}

.project-delete-btn:active {
    color: yellow;
}