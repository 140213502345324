.floating-menu-item-wrapper, .floating-menu-switcher-wrapper, .floating-menu-switcher-item-wrapper {
    background: #FAFEFF;
    border-radius: 0.625rem;
    height: 2.5rem;
    width: 2.5rem;
    margin: 0.3125rem 0.25rem;
}

.floating-menu-switcher-wrapper {
    border-bottom-right-radius: 0;
}

.floating-menu-item-wrapper:hover, .floating-menu-switcher-wrapper:hover {
    border: 0.125rem solid #FF983D;
    margin: 0.1875rem 0.125rem;
}

.floating-menu-item-wrapper-active {
    background-color: #FF983D;
}

.floating-menu-item {
    background-size: contain;
    height: 1.875rem;
    width: 1.875rem;
    margin: 0.3125rem;
}

.floating-menu-item-title {
    color: #144066;
    margin: 0.625rem;
    font-family: Roboto, sans-serif;
    font-size: 0.875rem;
    line-height: 1rem;
}

.floating-menu-item-body {
    position: fixed;
    min-width: 11.8125rem;
    background-color: #C4DEF5;
    border: 0.0625rem solid #144066;
    border-radius: 0.625rem;
}

.floating-menu-switcher-item-wrapper:hover {
    border: 0.125rem solid #FF983D;
    margin: 0.2rem 0.125rem;
}

.floating-menu-switcher-item-wrapper:active {
    background-color: #FF983D;
}

.floating-menu-switcher-body {
    position: fixed;
    background-color: #C4DEF5;
    border: 0.0625rem solid #144066;
    border-radius: 0.625rem;
    display: flex;
    padding: 0 0.0625rem;
    margin-top: -0.625rem;
}
