.project-analytics-link-to-studio {
    color: #144066;
    font-size: 1.25rem;
    padding: 0.5rem 1rem;
}

.project-analytics-link-to-studio:hover {
    font-weight: 900;
}

.project-analytics-tab-hidden-input {
    display: none;
}

.project-analytics-tab-label {
    background-color: #9CC6EA;
    border-radius: 1rem;
    color: #144066;
    cursor: pointer;
    display: inline-block;
    font-family: Rubik, sans-serif;
    font-style: normal;
    font-size: 1.25rem;
    line-height: 1.5rem;
    margin-left: 2rem;
    margin-top: 1rem;
    padding: 0.5rem 1rem;
    user-select: none;
}

.project-analytics-tab-label:hover {
    font-weight: 500;
}

.project-analytics-tab-hidden-input:checked+.project-analytics-tab-label {
    text-decoration: underline;
}

#project-analytics-tab-hidden-input-main:checked~#main-project-analytics-container {
    display: flex;
}

#project-analytics-tab-hidden-input-wfpm:checked~#main-project-analytics-other-container {
    display: flex;
}

.main-project-analytics-container {
    align-items: flex-start;
    display: none;
    justify-content: flex-start;
}

#main-project-analytics-other-container {
    align-items: flex-start;
    display: none;
    justify-content: flex-start;
    margin: 1rem;
}

.project-analytics-table-menu-container {
    color: #144066;
    background-color: #FAFEFF;
    height: calc(100vh - 6rem);
    margin-top: 2rem;
    padding-left: 1rem;
    padding-right: 0.5rem;
    width: 24rem;
}

.project-analytics-table-menu-container h3 {
    margin-bottom: 0;
}

.project-analytics-table-menu-container h5 {
    margin: 0 0 0.5rem;
}

.project-analytics-table-menu-container label {
    color: #144066;
    cursor: pointer;
    font-family: Roboto, sans-serif;
    font-size: 0.875rem;
}

.project-analytics-table-showing-handler {
    background-color: #FAFEFF;
    border: 0.1rem solid #144066;
    border-left: 0;
    border-top-right-radius: 0.625rem;
    border-bottom-right-radius: 0.625rem;
    cursor: pointer;
    height: calc(100vh - 6rem);
    margin: 2rem 2rem 0 0;
}

.project-analytics-table-showing-handler:hover {
    background-color: #9cc6ea;
}

.project-analytics-table-menu-container table {
    width: 22rem;
}

.project-analytics-table-menu-container thead {
    background-color: #FAFEFF;
    position: sticky;
    top: 0;
}

.project-analytics-table-menu-container thead td {
    font-size: 0.875rem;
    min-width: 3.5rem;
}

.project-analytics-table-menu-container thead td:hover {
    font-weight: 900;
}

.project-analytics-confine-mode {
    color: black;
    cursor: pointer;
}

.project-analytics-confine-mode-active {
    color: #144066;
    cursor: pointer;
    text-decoration: underline;
}

.project-analytics-table-cell-date {
    min-width: 6rem;
}

.project-analytics-table-cell-blue {
    background-color: #C4DEF5;
    min-width: 4rem;
    max-width: 10rem;
}

.project-analytics-exclude-user-textbox {
    border: 0.1rem solid #144066;
    border-radius: 0.25rem;
    color: #144066;
    width: 4rem;
}

.project-analytics-exclude-user-textbox::placeholder {
    color: rgba(20, 64, 104, 0.8);
}

.project-analytics-table-update-container {
    display: flex;
    margin: 1rem 0.5rem 0.5rem;
}

.project-analytics-table-update-button {
    background-color: #144066;
    border: 0.1rem solid #144066;
    border-radius: 0.25rem;
    color: #FAFEFF;
    cursor: pointer;
    margin-right: 0.5rem;
}

.project-analytics-table-update-button:hover {
    background-color: #C4DEF5;
    color: #144066;
}

.project-analytics-table-update-button:active {
    background-color: #FAFEFF;
}

.project-analytics-table-create-link {
    background: none;
    border: none;
    color: #144066;
    cursor: pointer;
    user-select: text;
}

.project-analytics-table-create-link:hover {
    font-weight: 900;
}

.project-analytics-dashed-container {
    border: 0.15rem dashed #144066;
    border-radius: 2rem;
    padding: 0 1rem 1rem 1rem;
    margin-bottom: 1rem;
    margin-top: 2rem;
}

.project-analytics-dashed-container h3 {
    color: #144066;
    font-family: Roboto, sans-serif;
}

.project-analytics-data-downloader-partition-container {
    align-items: flex-start;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin-top: 1rem;
}

.project-analytics-source-node-configurator-container {
    grid-column: 1 / 3;
    height: 15rem;
}

.project-analytics-source-node-configurator-grid {
    border-bottom: 0.15rem dashed black;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 2rem 1rem;
    padding-bottom: 1rem;
}

.project-analytics-source-node-configurator-option-container {
    display: flex;
    flex-direction: column;
}

.project-analytics-source-node-configurator-server-map-selector {
    display: flex;
    flex-direction: column;
}

.project-analytics-source-node-configurator-local-data-container {
    align-items: center;
    display: flex;
    grid-column: 2/4;
}

.project-analytics-source-node-configurator-local-data-container label {
    font-size: 0.75rem;
}

.project-analytics-source-node-configurator-data-loader {
    width: 7.5rem;
}

.project-analytics-source-node-configurator-custom-map-list {
    width: 10rem;
}

.project-analytics-data-type-container {
    border-bottom: 0.15rem dashed black;
}

[id^=project-analytics-table-cell-] {
    cursor: pointer;
}
