.confirmer-shadow-box {
    align-items: center;
    background-color: rgba(20, 64, 102, 0.6);
    border-radius: 0.625rem;
    display: flex;
    flex-direction: column;
    height: 19.75rem;
    justify-content: center;
    position: absolute;
    width: 18.125rem;
    z-index: 2;
}

.confirmer-shadow-box>span {
    color: #FAFEFF;
    font-family: Roboto, sans-serif;
    font-size: 1rem;
    font-weight: 500;
    line-height: 1.25rem;
    margin: 0 0 1rem;
    text-align: center;
}

.confirmer-shadow-box input {
    background-color: #144066;
    border: 0.125rem solid #144066;
    border-radius: 0.625rem;
    color: #FAFEFF;
    font-family: Roboto, sans-serif;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.25rem;
    margin: 0 0.5rem 0;
    padding: 0.5rem 1.5rem;
    text-align: center;
    text-transform: uppercase;
}

.confirmer-shadow-box input:hover {
    background-color: #FAFEFF;
    color: #144066;
}