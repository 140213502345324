.survey-p, .survey-p-bold {
    color: #144066;
    font-family: Roboto, sans-serif;
    font-size: 1rem;
    font-style: normal;
    line-height: 1.1875rem;
    max-width: 31.25rem;
    text-align: center;
}

.survey-p {
    font-weight: normal;
}

.survey-p-bold {
    font-weight: bold;
}

.survey-answer-radio-wrapper {
    margin-right: 1rem;
    margin-top: 0.5rem;
}

.survey-answer-radio-hidden-std {
    opacity: 0;
    position: absolute;
    z-index: -1;
}

.survey-answer-radio-label {
    color: #144066;
    font-family: Roboto, sans-serif;
    font-size: 1rem;
    font-style: normal;
    font-weight: normal;
}

.survey-answer-radio-hidden-std+.survey-answer-radio-label::before {
    background-color: #9CC6EA;
    border-radius: 50%;
    content: '';
    display: inline-block;
    height: 1rem;
    margin-bottom: -0.1rem;
    margin-right: 0.7rem;
    width: 1rem;
}

.survey-answer-radio-hidden-std:checked+.survey-answer-radio-label::before {
    background-color: #144066;
    border: 0.2rem solid #9CC6EA;
    height: 0.6rem;
    width: 0.6rem;
}

input[type="number"].survey-answer-number-input {
  -webkit-appearance: textfield;
     -moz-appearance: textfield;
          appearance: textfield;
}
input[type=number].survey-answer-number-input::-webkit-inner-spin-button,
input[type=number].survey-answer-number-input::-webkit-outer-spin-button {
  -webkit-appearance: none;
}

.survey-answer-number-container {
    display: flex;
}

.survey-answer-number-button-container {
    display: flex;
    flex-direction: column;
}

.survey-answer-number-input {
    background: #9CC6EA;
    border: 0.0625rem solid #144066;
    border-right: none;
    border-top-left-radius: 0.625rem;
    border-bottom-left-radius: 0.625rem;
    color: #144066;
    font-weight: normal;
    font-style: normal;
    font-size: 1.125rem;
    height: 1.5625rem;
    line-height: 1.5625rem;
    outline: none;
    padding-left: 0.375rem;
    width: 3.125rem;
}

.survey-answer-number-button {
    background: #9CC6EA;
    border: none;
    border-right: 0.0625rem solid #144066;
    color: #144066;
    height: 0.875rem;
    font-weight: normal;
    font-style: normal;
    font-size: 0.625rem;
    line-height: 0.6875rem;
    outline: none;
    padding: 0.0625rem 0.25rem;
}

.survey-answer-number-button-increase {
    border-top: 0.0625rem solid #144066;
    border-top-right-radius: 0.625rem;
}

.survey-answer-number-button-decrease {
    border-bottom: 0.0625rem solid #144066;
    border-bottom-right-radius: 0.625rem;
}


.survey-answer-text-input {
    background: #9CC6EA;
    border: 0.0625rem solid #144066;
    border-radius: 0.625rem;
    color: #144066;
    font-weight: normal;
    font-style: normal;
    font-size: 1.125rem;
    line-height: 1.5625rem;
    outline: none;
    padding: 0.0625rem 0.375rem;
    width: 25rem;
}

.survey-answer-rating-container {
    display: flex;
    justify-content: space-around;
    width: 9.375rem;
}

.survey-answer-rating-item {
    align-items: center;
    display: flex;
    font-weight: normal;
    font-style: normal;
    font-size: 1.125rem;
    height: 3.125rem;
    justify-content: center;
    line-height: 1.5625rem;
    user-select: none;
    width: 1.25rem;
}

.survey-finish-title {
    margin-bottom: 6.25rem;
    margin-top: 6.25rem;
}

.survey-dropdown {
    display: inline-block;
    position: relative;
}

.survey-answers-container {
    background-color: #9CC6EA;
    border-radius: 0.625rem;
    display: none;
    max-height: 25rem;
    overflow-y: hidden;
    padding: 0.3125rem 0.625rem;
    position: absolute;
    width: 24.6875rem;
    z-index: 1;
}

.survey-dropdown-opened .survey-answers-container {
    display: block;
}

.survey-possible-answer, .survey-possible-answer-active {
    border-radius: 0.625rem;
    margin: 0.3125rem;
    padding: 0.3125rem;
    user-select: none;
}

.survey-possible-answer {
    color: #144066;
}

.survey-possible-answer-active {
    background-color: #144066;
    color: #C4DEF5;
}
