.footer {
    align-content: center;
    align-items: normal;
    background-color: #144066;
    bottom: 0;
    color: #C4DEF5;
    display: grid;
    grid-template-areas: "title title desc desc ." "icons backgrounds tools . about" "polygons templates creative-maps . about" "copyright copyright copyright . .";
    grid-template-columns: 10.625rem 10.625rem 21.7rem 25.05rem 12rem;
    grid-template-rows: min(8vh, 5rem) 2.5rem 5.8rem 1.25rem;
    font-family: OpenSans;
    font-style: normal;
    font-size: 1.5rem;
    height: calc(min(8vh, 5rem) + 2.5rem + 5.8rem + 1.25rem);
    justify-content: center;
    min-width: 55rem;
    padding: min(3vh, 3.75rem) 5.625rem 1.5rem;
    position: relative;
    user-select: none;
    width: calc(100% - 11.25rem);
}

.footer a {
    text-decoration: none;
}

.footer a:hover {
    font-weight: 400;
}

.footer a:hover span {
    opacity: 1;
}

.title {
    align-items: center;
    font-size: 1rem;
    grid-area: title;
    width: 15.8125rem;
    display: flex;
    flex-direction: row;
}

.name {
    height: auto;
    padding-left: 0;
}

.desc {
    align-items: center;
    align-self: auto;
    color: #FAFEFF;
    display: flex;
    font-family: Roboto, sans-serif;
    font-size: 1rem;
    font-weight: 300;
    grid-area: desc;
    line-height: 1.25rem;
    margin-left: -5.2rem;
}

.copyright {
    align-self: auto;
    grid-area: copyright;
    padding: 0;
}

/*baseItems*/

.about {
    align-self: auto;
    cursor: pointer;
    display: grid;
    font-size: 0.875rem;
    grid-area: about;
    grid-template-rows: 2.125rem 2.125rem 2.125rem;
    padding: 0;
}

.about a, .copyright {
    color: #C4DEF5;
    font-family: Roboto, sans-serif;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 100;
    letter-spacing: 0.0292rem;
    line-height: 1.25rem;
}

.about a:hover {
    font-weight: 200;
}

/*specificItems*/

.icons a, .backgrounds a, .tools a, .polygons a, .templates a, .creative-maps a {
    color: #C4DEF5;
    cursor: pointer;
    font-family: Roboto, sans-serif;
    font-size: 1rem;
    font-style: normal;
    font-weight: 300;
    letter-spacing: 0.0292rem;
    line-height: 1.5rem;
}

.icons {
    grid-area: icons;
}

.backgrounds {
    grid-area: backgrounds;
}

.tools {
    grid-area: tools;
}

.polygons {
    grid-area: polygons;
}

.templates {
    grid-area: templates;
}

.creative-maps {
    grid-area: creative-maps;
}

.creative-maps span {
    display: inline-block;
    transform: scale(1.5, 1) translate(0, 3%);
    opacity: 0.7;
    font-weight: 100;
}
