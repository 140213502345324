.footer {
    bottom: 0;
    color: #074479;
    display: flex;
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 0.75rem;
    grid-row: social;
    justify-content: space-between;
    line-height: 1.5rem;
    padding: 0.3125rem 0.625rem;
}

.footer-sharing-container {
    display: flex;
    align-items: center;
}

.footer-sharing-container:last-child>* {
    margin-left: 0.375rem;
}

.footer-sharing-container:last-child>.slider-container {
    margin-left: 0.625rem;
}

.footer-icon-wrapper {
    background-color: #C4DEF5;
    border-radius: 0.375rem;
    height: 1.5rem;
    margin: 0 0.3125rem;
    padding: 0.1875rem;
    width: 1.5rem;
}

.footer-icon-wrapper:hover {
    border: 0.125rem solid #143D66;
    padding: 0.0625rem;
}

.sharing-icon-wrapper {
    border: 0.125rem solid #9CC6EA;
    border-radius: 0.375rem;
    height: 2.5rem;
    margin: 0 0.3125rem;
    padding: 0.1875rem;
    width: 2.5rem;
}

.sharing-icon-wrapper:hover {
    border: 0.125rem solid #143D66;
}

.footer-studio-name {
    font-size: 0.875rem;
    font-weight: 500;
    height: 1.5rem;
    margin: 0.1875rem 0 0 0.3125rem;
}

.footer-normal-text {
    font-size: 0.8175rem;
    height: 1.5rem;
    margin-right: 0.3125rem;
}

.footer-company-name {
    font-size: 0.8175rem;
    height: 1.5rem;
    margin: 0.1875rem 0 0 0.25rem;
}

@media (max-width: 1000px) {
    .footer-studio-name {
        font-size: 0.5rem;
        margin-left: 0.5rem;
    }

    .footer-company-name {
        font-size: 0.5rem;
    }
}

a:first-child {
    margin-right: 1rem;
}

@media (max-width: 1000px) {
    a:first-child {
        margin-right: 0.5rem;
    }
}

.footer-share-wrapper {
    background-color: #9CC6EA;
    border-radius: 0.625rem;
    display: flex;
    flex-wrap: wrap;
}

@media (max-width: 1000px) {
    .footer-share-wrapper {
        margin: 0 0.25rem;
    }
}

.studio-context-menu-related-users {
    border: 0.0625rem solid #144066;
    width: 12.5rem;
}

.studio-footer-share-field {
    width: 18.75rem;
}

.studio-footer-share-field, .studio-context-menu-related-users {
    background-color: #C4DEF5;
    border-radius: 0.375rem;
    color: #144066;
    font-family: Roboto, sans-serif;
    margin: 0 0.3125rem;
}

.studio-footer-share-field div, .studio-context-menu-related-users div {
    color: inherit;
    padding: 0;
}

.studio-footer-share-field div[class$="-control"], .studio-context-menu-related-users div[class$="-control"]{
    background-color: transparent;
    background-repeat: repeat;
    border: 0.125rem solid #C4DEF5;
    border-radius: 0.625rem;
    box-sizing: border-box;
    box-shadow: none;
    color: #144066;
    font-family: Roboto, sans-serif;
    font-size: 0.875rem;
    line-height: 1rem;
    min-height: auto;
    padding: 0.0625rem 0 0.0625rem 0.625rem;
    width: 100%;
}

.studio-footer-share-field div[class*="control--is-focused"], .studio-context-menu-related-users  div[class*="control--is-focused"]{
    background-color: #C4DEF5;
}

.studio-footer-share-field div[class$="menu"], .studio-context-menu-related-users div[class$="menu"] {
    background-color: #FAFEFF;
    border: 0.125rem solid #C4DEF5;
    border-radius: 0.625rem;
    line-height: 2rem;
    min-height: 2rem;
    z-index: 3000;
}

.studio-footer-share-field div[class*="menu-list"], .studio-context-menu-related-users div[class*="menu-list"] {
    background-color: #FAFEFF;
    border-radius: 0.625rem;
    margin: 0;
}

.studio-footer-share-field div[class*="placeholder"], .studio-context-menu-related-users div[class*="placeholder"] {
    opacity: 0.6;
}

.studio-footer-share-field div[class*="value-container--has-value"], .studio-context-menu-related-users {
    flex-wrap: nowrap;
    opacity: 1;
}

.studio-footer-share-field div[class*="option"], .studio-context-menu-related-users div[class*="option"] {
    background-color: #FAFEFF;
    color: #144066;
    font-family: Roboto, sans-serif;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5rem;
    padding: 0.3125rem 0.625rem;
}

.studio-footer-share-field div[class*="option"]:hover, .studio-context-menu-related-users div[class*="option"]:hover {
    background-color: #C4DEF5;
}

.studio-footer-share-field div[class*="option"]:first-child, .studio-context-menu-related-users div[class*="option"]:first-child {
    padding-top: 0.625rem;
}

.studio-footer-share-field div[class*="option"]:last-child, .studio-context-menu-related-users div[class*="option"]:last-child {
    padding-bottom: 0.625rem;
}

.studio-footer-share-field div[class*="clear-indicator"], .studio-context-menu-related-users div[class*="clear-indicator"] {
    margin-right: 0.5rem;
}

.studio-footer-help-button {
    cursor: pointer;
    margin-left: 0.25rem;
    margin-right: 0.25rem;
    user-select: none;
}


.studio-modal-share-field {
    color: #144066;
    font-family: Roboto, sans-serif;
    width: 13rem;
}

.studio-modal-share-field div{
    color: inherit;
    padding: 0;
}

.studio-modal-share-field div[class$="-control"] {
    background-color: transparent;
    background-repeat: repeat;
    border: 0.125rem solid transparent;
    border-radius: 0.625rem;
    box-sizing: border-box;
    box-shadow: none;
    color: #144066;
    font-family: Roboto, sans-serif;
    font-size: 0.875rem;
    line-height: 1rem;
    min-height: auto;
    padding: 0.5rem;
    /*width: 100%;*/
}

.studio-modal-share-field div[class*="control--is-focused"] {
    background-color: #C4DEF5;
}

.studio-modal-share-field div[class$="menu"] {
    background-color: #C4DEF5;
    border: 0.0625rem solid #144066;
    border-radius: 0.625rem;
    line-height: 2rem;
    min-height: 2rem;
    margin-top: 1rem;
    z-index: 3000;
}

.studio-modal-share-field div[class*="menu-list"] {
    background-color: #C4DEF5;
    border: 0.0625rem solid #144066;
    border-radius: 0.625rem;
}

.studio-modal-share-field div[class*="placeholder"] {
    opacity: 0.6;
}

.studio-modal-share-field div[class*="ValueContainer"] {
    flex-wrap: wrap;
    row-gap: 0.625rem;
    opacity: 1;
}

.studio-modal-share-field div[class*="option"] {
    background-color: #C4DEF5;
    border-radius: 0.625rem;
    color: #144066;
    font-family: Roboto, sans-serif;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5rem;
    padding: 0.3125rem 0.625rem;
}

.studio-modal-share-field div[class*="option"]:hover {
    background-color: #9CC6EA;
}

.studio-modal-share-field div[class*="option"]:first-child {
    padding-top: 0.625rem;
}

.studio-modal-share-field div[class*="option"]:last-child {
    padding-bottom: 0.625rem;
}

.studio-modal-share-field div[class*="clear-indicator"] {
    margin-right: 0.5rem;
}

.studio-modal-share-field div[class*="multiValue"] {
    align-items: center;
    background-color: #9CC6EA;
    border: 0.0625rem solid #144066;
    border-radius: 0.375rem;
    color: #144066;
    display: flex;
    line-height: 1.5rem;
    margin: 0 0.25rem 0 0;
    padding: 0.25rem 0.375rem;
}

.studio-modal-share-field div[class*="multiValue"] div {
    font-size: 1rem;
}

.studio-modal-share-field div[class*="multiValue"] div[role="button"] {
    height: 1rem;
    width: 1rem;
}

.studio-modal-share-field div[class*="multiValue"] div[role="button"]:hover {
    background-color: transparent;
}

.studio-modal-share-field div[class*="multiValue"] div[role="button"]:hover svg {
    fill: #C4DEF5;
    stroke: #C4DEF5;
}
