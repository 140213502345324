.search {
    display: block;
    cursor: pointer;
    height: 1.8rem;
    width: 1.8rem;
    margin-right: 2rem;
    min-height: 1.8rem;
    min-width: 1.8rem;
}

.search:hover {
    stroke-width: 0.1875rem;
}

.search-active-wrapper {
    background-color: white;
    border: 0.125rem solid #C4DEF5;
    border-left: none;
    border-bottom-right-radius: 0.625rem;
    border-top-right-radius: 0.625rem;
    margin-right: 1.375rem;
    padding: 0.375rem 0.5rem 0.375rem 0;
}

.search-active-input:focus+.search-active-wrapper {
    background-color: #C4DEF5;
}

.search-active-input {
    border: 0.125rem solid #C4DEF5;
    border-right: none;
    border-bottom-left-radius: 0.625rem;
    border-top-left-radius: 0.625rem;
    color: #144066;
    font-family: Roboto, sans-serif;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5rem;
    outline: none;
    padding: 0.5rem 0.625rem;
    width: 12.725rem;
}

.search-active-input:focus {
    background-color: #C4DEF5;
}

.search-active-input::placeholder {
    color: #144066;
    opacity: 0.6;
}

.search-active-input::-moz-placeholder {
    color: #144066;
    opacity: 0.6;
}

.search-active-input::-webkit-input-placeholder {
    color: #144066;
    opacity: 0.6;
}

.search-active-input::-ms-input-placeholder {
    color: #144066;
    opacity: 0.6;
}

.header-search-active-icon {
    fill: #144066;
    opacity: 0.8;
    stroke: #144066;
    height: 28px;
    width: 36px;
}