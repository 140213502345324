.context-menu {
    background: #C4DEF5;
    border-radius: 0.625rem;
    border: 0.0625rem solid #144066;
    margin-top: 0;
    margin-left: 0;
    padding: 0.3125rem;
    position: absolute;
    z-index: 200;
}

.context-menu-row {
    display: flex;
}

.context-menu-button {
    margin: 0.3125rem;
    padding-bottom: 0.1875rem;
}

.context-menu-tool-wrapper {
    position: relative;
}

.context-menu-color-picker {
    align-items: center;
    display: flex;
    flex-direction: column;
}

.github-picker {
    border: none !important;
    border-radius: 0.625rem !important;
    overflow: hidden;
    padding: 0 !important;
    width: 10.9375rem !important;
}

.context-menu-emoji-picker {
    background: none;
    padding: 0.3125rem;
    width: 10.625rem;
}

.context-menu-slider-container {
    align-items: center;
    background-color: transparent;
    border: 0;
    border-radius: 0.625rem;
    display: flex;
    flex-direction: column;
    padding: 0.625rem;
}

.context-menu-slider-title {
    color: #074479;
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 0.875rem;
    line-height: 1rem;
    margin-top: 0;
    min-width: 1.875rem;
}

.context-menu-slider {
    width: 10rem !important;
}
