.avatar-flex {
    border-radius: 0.625rem;
    height: 100%;
    width: 100%;
}

.avatar-wrapper {
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: center;
    width: 100%;
}
