
.figure-bar {
    background-color: #9CC6EA;
    display: flex;
    flex-direction: row;
    grid-row: toolbar;
    margin: 0 0.625rem 0;
    overflow: visible;
}

.figure-bar>* {
    margin-bottom: 0.625rem;
}

.figure-bar>*:not(:first-child) {
    margin-left: 0.625rem;
}

.tool-blocks-container {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-width: 22rem;
    user-select: none;
    width: 22rem;
}

.tool-blocks-container div {
    border-radius: 0.625rem;
}

.tool-blocks-container p {
    color: #143D66;
    font-size: 0.75rem;
    font-family: Roboto, sans-serif;
    line-height: 1rem;
    margin: 0;
}

.first-tool-block, .second-tool-block {
    align-items: center;
    background-color: #C4DEF5;
    display: flex;
    user-select: none;

}

.first-tool-block {
    height: 3.75rem;
    justify-content: space-around;
    width: 22rem;
}

.second-tool-block {
    height: 4.375rem;
    justify-content: space-between;
    padding: 0 0.625rem;
    width: 20.75rem;
}

.tool-block-item, .tool-block-big-item {
    cursor: pointer;
}

.tool-block-item {
    height: 1.875rem;
    width: 1.875rem;
}

.tool-block-item:hover svg:not(.disabled) {
    fill: #FF983D;
    stroke: #FF983D;
}

.tool-block-big-item {
    background-color: #FAFEFF;
    border: 0.125rem solid #FAFEFF;
    height: 2.25rem;
    position: relative;
    width: 2.25rem;
}

.tool-block-big-item:hover {
    border-color: #FF983D;
}

.tool-block-big-item>* {
    margin: 0.1875rem;
}

.tool-block-big-item .sketch-picker {
    background-color: #C4DEF5 !important;
    border: 0.0625rem solid #144066;
    position: absolute;
    z-index: 110;
}


.slider-container {
    display: flex;
    align-items: center;
}

.slider-title {
    color: #074479;
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 0.75rem;
    line-height: 1.5rem;
    margin-left: 0.625rem;
    margin-top: 0;
}


.lang-selector-container {
    cursor: pointer;
    position: relative;
    width: 2.8125rem;
}

.lang-current {
    font-size: 0.875rem;
    line-height: 1.5rem;
    color: #143D66;
    width: 2.1875rem;
    padding-left: 0.625rem;
}

.lang-selector-container:hover>.lang-current {
    background-color: #C4DEF5;
    border-bottom-left-radius: 0.625rem;
    border-bottom-right-radius: 0.625rem;
    padding-left: 0.5625rem;
    border: 0.0625rem solid #144066;
    border-top: 0;
}

.lang-current span {
    display: inline-block;
    transform: scaleY(0.5);
    line-height: 0.625rem;
}

.lang-selector-item-container {
    display: none;
}

.lang-selector-container:hover>.lang-selector-item-container {
    background-color: #C4DEF5;
    border-top-left-radius: 0.625rem;
    border-top-right-radius: 0.625rem;
    display: initial;
    margin: 0;
    position: absolute;
    bottom: 1.5rem;
    padding: 0 0.5625rem;
    border: 0.0625rem solid #144066;
    border-bottom: none;
    width: 1.625rem;
    z-index: 200;
}

.lang-selector-item {
    list-style-type: none;
    margin: 0.375rem 0;
    padding: 0;
    color: #144066;
    font-weight: normal;
}

.lang-selector-item:hover {
    font-weight: 900;
}


.editor-mode-switch {
    display: flex;
    align-items: center;
    border-radius: 0.25rem;
    background-color: #C4DEF5;
    padding: 0.1875rem;
}

.editor-mode-item {
    cursor: pointer;
    height: 1.5rem;
    width: 1.5rem;
    border-radius: 0.25rem;
}

.editor-mode-item:not(:last-child) {
    margin-right: 0.1875rem;
}


.quick-launch-bar {
    background-color: #C4DEF5;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(3, 1fr);
    grid-column-gap: 0.3125rem;
    grid-row-gap: 0.625rem;
    padding: 0.6875rem 0.3125rem;
}

.quick-launch-bar svg .quick-launch-bar-icon-stroke {
    stroke: #144066;
}

.quick-launch-bar svg:hover .quick-launch-bar-icon-stroke {
    stroke: #FF983D;
}

.quick-launch-bar svg .quick-launch-bar-icon-fill {
    fill: #144066;
}

.quick-launch-bar svg:hover .quick-launch-bar-icon-fill {
    fill: #FF983D;
}


.figure-bar-right-container {
    user-select: none;
    overflow: hidden;
    flex-basis: auto;
    flex-grow: 2;
}

.figure-bar-tab-title-scroll-container {
    display: flex;
    flex-direction: row;
    overflow-x: auto;
}

.figure-bar-tab-title-container {
    color: #144066;
    list-style-type: none;
    display: flex;
    align-items: flex-start;
    margin: 0;
    padding: 0;
}

.figure-bar-tab-title, .figure-bar-tab-title-active {
    cursor: pointer;
    padding: 0.375rem 1.25rem;
    white-space: nowrap;
}

.figure-bar-tab-title:not(.figure-bar-tab-title-active+.figure-bar-tab-title):not(:first-child) {
    padding-left: 0;
}

.figure-bar-tab-title:not(.figure-bar-tab-title-active+.figure-bar-tab-title):not(:first-child)::before {
    content: "|";
    font-size: 1.25rem;
    line-height: 0.875rem;
    display: inline-block;
    transform: scaleX(0.5);
    margin-right: 1.25rem;
}

.figure-bar-tab-title-active {
    background-color: #C4DEF5;
    border-top-left-radius: 0.625rem;
    border-top-right-radius: 0.625rem;
}

.figure-bar-tab-content-wrapper {
    align-items: center;
    background-color: #C4DEF5;
    border-radius: 0 0.625rem 0.625rem 0.625rem;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
}

.figure-bar-tab-content-loader-wrapper {
    align-items: center;
    display: flex;
    justify-content: center;
    height: 8.75rem;
    min-width: 100%;
}

.figure-bar-tab-content-loader-title {
    color: #144066;
    font-family: Roboto, sans-serif;
    font-size: 1.25rem;
    font-weight: 400;
    margin-left: 1rem;
}

.figure-bar-tab-content-container {
    align-items: center;
    border-top-right-radius: 0.625rem;
    border-bottom-right-radius: 0.625rem;
    display: flex;
    flex-wrap: nowrap;
    flex-grow: 2;
    justify-content: stretch;
    overflow: hidden;
    height: 6.875rem;
    width: 10rem;
}

.figure-bar-partition {
    align-items: flex-start;
    background: #FAFEFF;
    border-radius: 0.625rem;
    display: flex;
    flex-direction: column;
    height: 5.625rem;
    min-width: 3.125rem;
    overflow: hidden;
}

.figure-bar-partition-bottom-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.figure-bar-partition-bottom-container-complicated {
    display: flex;
    justify-content: space-between;
    margin-top: 0;
    width: 100%;
    z-index: 0;
}

.tool-arrow {
    stroke:#9CC6EA;
    stroke-linecap: round;
    stroke-width: 0.125rem;
}

.tool-arrow:hover {
    stroke:#FF983D;
}

.arrow-next-tool, .arrow-prev-tool {
    background-color: transparent;
    height: 0.75rem;
    margin: 0.375rem 0.625rem 0.625rem;
    width: 0.75rem;
}

.figure-bar-partition-title {
    color: #143D66;
    font-size: 0.875rem;
    font-family: Roboto, sans-serif;
    line-height: 1.5rem;
    margin: 0;
    pointer-events: none;
    white-space: nowrap;
}

.figure-bar-partition-resizer {
    cursor: url(../../../studio/images/toolBar/icon-resize-partition-cursor.svg) 12 10, auto;
    height: 2.125rem;
    margin: 0 0.1875rem;
    min-width: 0.25rem;
    pointer-events: all;
    width: 0.25rem;
}

.figure-bar-partition-item-container {
    display: flex;
    margin: 0 0.5rem;
}

.figure-bar-tool {
    margin-left: 0.3125rem;
    height: 2.5rem;
    width: 2.5rem;
}

.figure-bar-tool-wrapper {
    border-radius: 0.625rem;
    height: 2.8125rem;
    margin: 0.625rem 0.125rem 0.3125rem;
    padding-top: 0.3125rem;
    width: 3.125rem;
}

.figure-bar-tool-wrapper:hover {
    border: 0.125rem solid #FF983D;
    margin: 0.5rem 0 0.1875rem;
}

.figure-bar-tool-wrapper-subpartition {
    background-color: #FAFEFF;
    border-radius: 0.625rem;
    height: 2.8125rem;
    margin: 0.3125rem;
    padding-top: 0.3125rem;
    width: 3.125rem;
}

.figure-bar-tool-wrapper-subpartition:hover {
    border: 0.125rem solid #FF983D;
    margin: 0.1875rem;
}

.figure-bar-subpartition {
    height: 2.5rem;
    margin: 0.3125rem 0.3125rem 0;
    width: 2.5rem;
}

.figure-bar-subpartition-preview-container {
    border-radius: 0.625rem;
    height: 3.75rem;
    margin: 0.3125rem 0.125rem 0;
    outline: none;
    width: 3.125rem;
}

.figure-bar-subpartition-preview-container:hover {
    border: 0.125rem solid #FF983D;
    margin: 0.1875rem 0 -0.125rem;
}

.figure-bar-subpartition-active {
    height: 2.5rem;
    margin: 0.3125rem 0.3125rem 0;
    pointer-events: none;
    width: 2.5rem;
}

.figure-bar-subpartition-image {
    background-color: #C4DEF5;
    border: 0.0625rem solid #144066;
    border-bottom: 0;
    border-top-left-radius: 0.625rem;
    border-top-right-radius: 0.625rem;
    height: 3.875rem;
    margin-top: 0.1875rem;
    position: absolute;
    width: 3.25rem;
    z-index: 300;
}

.figure-bar-subpartition-container {
    background-color: #C4DEF5;
    border: 0.0625rem solid #144066;
    border-radius: 0 0.625rem 0.625rem 0.625rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    max-width: 11.25rem;
    outline: none;
    padding: 0.3125rem;
    position: absolute;
    z-index: 200;
}

.figure-bar-subpartition-title {
    color: #143D66;
    font-family: Roboto, sans-serif;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: normal;
    line-height: 1.125rem;
    text-align: center;
    width: 100%;
    /*перенос по словам, но если не помещается, то и слово разорвать можно*/
    word-break: break-word;
    overflow-wrap: anywhere;
    outline: none;
}

.figure-bar-subpartition-arrow {
    stroke: #143D66;
    stroke-width: 0.125rem;
    stroke-linecap: round;
}

.partition-arrow {
    stroke-width: 0.125rem;
    stroke-linecap: round;
    stroke: #144066;
}

.partition-arrow:hover {
    stroke: #FF983D;
}

.arrow-next-partition {
    height: 0.875rem;
    margin-left: 0.125rem;
    margin-right: 0.0625rem;
    max-width: 0.5rem;
    min-width: 0.5rem;
}

.arrow-prev-partition {
    height: 0.875rem;
    margin-left: 0.125rem;
    margin-right: 0.0625rem;
    max-width: 0.5rem;
    min-width: 0.5rem;
}

.figure-bar-action-history-container {
    align-items: center;
    background-color: #C4DEF5;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    width: 100%;
}

.figure-bar-action-history-plot-container {
    display: flex;
    align-items: flex-start;
    height: 80%;
    width: 100%;
}

.figure-bar-action-history-plot {
    background-color: #FAFEFF;
    border-radius: 0.25rem;
    margin: 0.5rem 0.5rem 0.5rem 0;
    height: calc(100% - 1rem);
}

.figure-bar-action-history-max-frequency {
    font-family: Roboto, sans-serif;
    color: #144066;
    font-size: 0.75rem;
    margin: 0.7rem 0.2rem 0 0.5rem;
}

.figure-bar-action-history-time-info {
    font-family: Roboto, sans-serif;
    color: #144066;
    font-size: 1rem;
}
