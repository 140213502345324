.payment-form {
    background-color: #FAFEFF;
    display: grid;
    justify-content: center;
    align-content: flex-start;
    height: calc(100vh - 17.8rem);
}

.payment-form label {
    color: #0F1D23;
    font-family: Roboto, sans-serif;
    font-size: 1rem;
    font-weight: 300;
    line-height: 1.5rem;
}

.payment-form span {
    color: #122E73;
    font-family: Roboto, sans-serif;
    font-size: 1rem;
    font-weight: 300;
    line-height: 1.25rem;
}

.payment-form a {
    text-align: center;
}

.payment-wrapper {
    display: grid;
    max-width: 26.25rem;
}

.payment-brief-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 4.625rem 2.625rem 3rem;
    padding: 0;
}

.payment-brief-container p {
    color: #144066;
    font-family: Rubik, sans-serif;
    font-size: 1.5rem;
    font-weight: 500;
    line-height: 1.75rem;
    grid-column-start: span 2;
    margin: 1.875rem 0 1rem;
}

.payment-card-data-container {
    background-color: #C4DEF5;
    border-radius: 0.625rem;
    display: grid;
    grid-template-columns: 12.5rem 8.75rem;
    grid-template-rows: 1.875rem 4rem 1.875rem 4rem 1.875rem 4rem;
    height: 17.625rem;
    margin-bottom: 2.5rem;
    padding: 1.125rem 2.5rem 1.75rem;
}

.long-cell {
    grid-column-start: span 2;
}

.payment-validity-wrapper, payment-cvc-wrapper {
    width: 8.75rem;
}

.pay-or-back-container {
    display: flex;
    justify-content: center;
    padding: 0 7rem 0 7rem;
    flex-direction: column;
}

.payment-form-field {
    background-color: #9CC6EA;
    border: 0.125rem solid #9CC6EA;
    border-radius: 0.625rem;
    box-sizing: border-box;
    color: #144066;
    font-family: Roboto, sans-serif;
    font-size: 0.875rem;
    height: 2.5rem;
    line-height: 1.5rem;
    padding: 0 0 0 0.625rem;
    width: 100%;
}

.payment-form-field::placeholder {
    color: #144066;
    opacity: 0.6;
}

.payment-form-field::-moz-placeholder {
    color: #144066;
    opacity: 0.6;
}

.payment-form-field::-webkit-input-placeholder {
    color: #144066;
    opacity: 0.6;
}

.payment-form-field::-ms-input-placeholder {
    color: #144066;
    opacity: 0.6;
}

.payment-form-field:focus {
    border: none;
    outline: none;
}