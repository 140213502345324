.rating {
    align-items: center;
    display: flex;
    justify-content: flex-start;
    width: auto;
}

.rating > div {
    align-items: center;
    display: flex;
    justify-content: center;
}
