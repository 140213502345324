
.wfpm-p-visualizer-container {
    color: #144066;
    display: flex;
}

.wfpm-p-visualizer-svg-container {
    height: 43rem;
    width: 100%;
}

.wfpm-p-visualizer-menu-container {
    height: 43rem;
}

.wfpm-p-visualizer-menu-dispersion-container {
    align-items: center;
    display: flex;
    height: 3rem;
}
