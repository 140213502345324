.radio-group {
    align-items: center;
    display: flex;
    grid-template-columns: 1.8rem 5rem;
    grid-template-rows: 1.3rem 1.3rem 1.3rem 1.3rem;
    font-size: 1rem;
    grid-row-gap: 0.45rem;
    margin: 0.5rem 0 0.7rem 0;
}

.radio {
    display: flex;
    width: 1rem;
    height: 1rem;
    position: relative;
}

.input {
    cursor: pointer;
    position: absolute;
    opacity: 0;
    z-index: -1;
}
