

.admin-panel-container {
    align-items: center;
    background-color: #b7184a;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    width: 100%;
}

.admin-panel-container h1 {
    color: #FAFEFF;
    font-size: 4rem;
    letter-spacing: 0.3rem;
}

.admin-panel-container h2 {
    color: #FAFEFF;
    font-size: 3rem;
    letter-spacing: 0.2rem;
}

.admin-panel-container input {
    background-color: #6c0b0b;
    border: none;
    border-radius: 0.625rem;
    color: #FAFEFF;
    font-size: 2rem;
    margin-top: 2rem;
    padding: 0.5rem 1rem;
}

.admin-panel-container input:hover {
    background-color: #FAFEFF;
    color: #6c0b0b;
}

.admin-panel-container input:active {
    font-weight: 900;
    padding: 0.5rem 0.625rem;
}

.admin-panel-multiselect {
    background-color: #6c0b0b;
    border-radius: 0.375rem;
    color: #FAFEFF;
    font-family: Roboto, sans-serif;
    margin: 0 0.3125rem;
    width: 40rem;
}

.admin-panel-multiselect div {
    color: inherit;
    padding: 0;
}

.admin-panel-multiselect div[class$="-control"] {
    background-color: transparent;
    background-repeat: repeat;
    border: 0.125rem solid #FAFEFF;
    border-radius: 0.625rem;
    box-sizing: border-box;
    box-shadow: none;
    color: #FAFEFF;
    font-family: Roboto, sans-serif;
    font-size: 2rem;
    line-height: 2.125rem;
    min-height: auto;
    padding: 0.0875rem 0 0.0875rem 0.625rem;
    width: 100%;
}

.admin-panel-multiselect div[class*="control--is-focused"] {
    background-color: #C4DEF5;
}

.admin-panel-multiselect div[class$="menu"] {
    background-color: #FAFEFF;
    border: 0.125rem solid #C4DEF5;
    border-radius: 0.625rem;
    line-height: 2rem;
    min-height: 4rem;
    z-index: 3000;
}

.admin-panel-multiselect div[class*="menu-list"] {
    background-color: #FAFEFF;
    border-radius: 0.625rem;
    margin: 0;
}

.admin-panel-multiselect div[class*="placeholder"] {
    opacity: 0.6;
}

.admin-panel-multiselect div[class*="value-container--has-value"] {
    flex-wrap: nowrap;
    opacity: 1;
}

.admin-panel-multiselect div[class*="option"] {
    background-color: #FAFEFF;
    color: #144066;
    font-family: Roboto, sans-serif;
    font-size: 2rem;
    font-weight: 400;
    line-height: 2.5rem;
    padding: 0.3125rem 0.625rem;
}

.admin-panel-multiselect div[class*="option"]:hover {
    background-color: #6c0b0b;
    color: #FAFEFF;
}

.admin-panel-multiselect div[class*="option"]:first-child {
    padding-top: 0.625rem;
}

.admin-panel-multiselect div[class*="option"]:last-child {
    padding-bottom: 0.625rem;
}

.admin-panel-multiselect div[class*="clear-indicator"] {
    margin-right: 0.5rem;
}