.card {
    color: #144066;
    display: grid;
    grid-row: auto;
    grid-row-gap: 0;
    grid-template-areas: "title title" "tags tags" "price rating";
    grid-template-columns: auto 6.625rem;
    grid-template-rows: 3.375rem 3.625rem 1.75rem;
    justify-self: baseline;
    max-width: 12.5rem;
    padding: 0;
    text-decoration: none;
    width: 100%;
}

.wrapper {
    background-color: #FAFEFF;
    border: 0.125rem solid #C4DEF5;
    border-radius: 0.625rem;
    color: #144066;
    display: grid;
    font-family: Roboto, sans-serif;
    grid-template-areas: "." "." "manage";
    grid-template-rows: 8.75rem 8.5rem auto;
    height: auto;
    justify-items: center;
    padding: 0.5rem;
    text-decoration: none;
    width: 12.5rem;
}

.wrapper:hover {
    border: 0.125rem solid #FF983D;
    box-shadow: none;
}

.name, .price {
    align-self: auto;
    color: #144066;
    display: block;
    font-weight: 600;
    height: auto;
    justify-self: auto;
    line-height: 1.5rem;
    overflow: hidden;
    padding-top: 0;
    text-overflow: clip;
    white-space: normal;
    width: 100%;
}

.name {
    font-size: 1.125rem;
    grid-area: title;
}

.price {
    grid-area: price;
    font-size: 1.3rem;
}

.rating {
    grid-area: rating;
    align-self: center;
    align-items: flex-end;
}

.text {
    align-self: auto;
    word-break: break-all;
    color: #144066;
    font-family: Roboto, sans-serif;
    font-size: 1rem;
    font-weight: 300;
    grid-area: tags;
    line-height: 1.375rem;
    overflow: hidden;
    text-overflow: clip;
    white-space: normal;
}

.managePanel {
    display: grid;
    grid-area: manage;
    grid-template-columns: 1fr 1.1fr;
    height: auto;
    position: static;
    width: 12.5rem;
    z-index: auto;
}

.managePanel span {
    cursor: pointer;
    width: 2.2rem;
}
