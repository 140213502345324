@font-face {
    font-family: Rubik;
    src: url("./styles/fonts/Rubik-VariableFont_wght.ttf");
    font-weight: 100 900; /*without this attribute Chrome render font incorrect in some cases*/
}

@font-face {
    font-family: Roboto-mono;
    src: url("./styles/fonts/RobotoMono-VariableFont_wght.ttf");
    font-weight: 100 900; /*without this attribute Chrome render font incorrect in some cases*/
}

@font-face {
    font-family: Roboto;
    src: url("./styles/fonts/Roboto-Thin.ttf");
    font-weight: 100;
}

@font-face {
    font-family: Roboto;
    src: url("./styles/fonts/Roboto-Light.ttf");
    font-weight: 200 300;
}

@font-face {
    font-family: Roboto;
    src: url("./styles/fonts/Roboto-Regular.ttf");
    font-weight: 400;
}

@font-face {
    font-family: Roboto;
    src: url("./styles/fonts/Roboto-Medium.ttf");
    font-weight: 500 600;
}

@font-face {
    font-family: Roboto;
    src: url("./styles/fonts/Roboto-Bold.ttf");
    font-weight: 700 800;
}

@font-face {
    font-family: Roboto;
    src: url("./styles/fonts/Roboto-Black.ttf");
    font-weight: 900;
}

@font-face {
    font-family: AnonymousPro;
    src: url("./styles/fonts/anonymouspro.woff");
}

body {
    margin: 0;
    padding: 0;
    font-family: sans-serif;
    background-color: #e5e5e5;
    height: 100vh;
    outline: none;
    overflow: hidden !important;
}

button, input, textarea, a {
    outline: none;
    text-decoration: none;
}

#root {
    height: 100%;
}

[class*="react-joyride"] a {
    color: #144066;
    text-decoration: underline;
}
