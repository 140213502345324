.ReactModal__Overlay--after-open {
    align-items: center;
    display: flex;
    justify-content: center;
}

.ReactModal__Content--after-open {
    left: initial !important;
    top: initial !important;
    right: initial !important;
    bottom: initial !important;
}

.modal-container, .modal-container-overflow-friendly {
    align-items: center;
    display: flex;
    flex-direction: column;
    min-width: 18.75rem;
    outline: none;
    overflow: hidden;
}

.modal-container-overflow-friendly {
    overflow: visible;
}

.modal-container a {
    color: #144066;
    text-decoration: underline;
}

.modal-title {
    margin-bottom: 2.625rem;
}

.modal-title, .modal-sharing-title {
    color: #144066;
    font-family: Rubik, sans-serif;
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.5rem;
    margin-top: 0.125rem;
    text-align: center;
}

.modal-sharing-title {
    margin-bottom: 1.5rem;
}

.modal-survey-title {
    color: #144066;
    font-family: Rubik, sans-serif;
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.5rem;
    margin: 0.125rem 0.9375rem 1.5625rem;
    max-width: 31.25rem;
    text-align: center;
}

.modal-sharing-section-container {
    align-items: center;
    margin-bottom: 1.875rem;
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.modal-share-buttons-container {
    display: flex;
    justify-content: flex-end;
}

.modal-share-invite-button {
    background-color: #C4DEF5;
    border-radius: 0.375rem;
    height: 1.25rem;
    margin: 1.125rem 0.625rem 0 0;
    width: 1.25rem;
}

.modal-p, .modal-sharing-p {
    color: #122E73;
    font-family: Roboto, sans-serif;
    font-size: 1rem;
    font-style: normal;
    font-weight: normal;
    line-height: 1.1875rem;
}

.modal-sharing-p {
    margin: 0;
}

.modal-button-exit {
    background: none;
    background-image: url(../../../studio/images/extraWindow/cross.svg);
    background-size: contain;
    border: none;
    cursor: pointer;
    height: 1rem;
    padding: 0;
    position: absolute;
    right: 1.25rem;
    top: 1.25rem;
    width: 1rem;
}

.modal-button-exit:focus, .modal-button-apply:focus {
    outline: none;
}

.modal-button, .modal-access-rights-button {
    background-color: #143D66;
    border-radius: 0.625rem;
    border: none;
    color: #FAFEFF;
    cursor: pointer;
    font-family: Rubik, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 1.25rem;
    height: 2.8125rem;
    line-height: 1.5rem;
    text-align: center;
}

.modal-access-rights-button {
    text-transform: uppercase;
    width: 100%;
}

.modal-access-rights-button:hover {
    background-color: #9cc6ea;
    color: #144066;
}

.modal-button-apply {
    letter-spacing: 0.05em;
    margin-top: 1.4375rem;
    text-transform: uppercase;
    width: 20rem;
}

.modal-button-postcard-apply {
    letter-spacing: 0.05em;
    margin: 0.625rem auto 0.625rem;
    text-transform: uppercase;
    width: 20rem;
}

.modal-button-survey {
    margin-bottom: 1.4375rem;
    margin-top: 1.4375rem;
    width: 20rem;
}

.modal-button-survey-question-next {
    margin-bottom: 1.4375rem;
    margin-top: 1.4375rem;
    width: 9.375rem;
}

.modal-button-survey-question-prev {
    margin: 1.4375rem 0.625rem 1.4375rem 0;
    width: 9.375rem;
}

.my-maps {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-gap: 1rem;
    grid-auto-rows: 7vw;
}

.my-maps-title-container {
    align-items: center;
    display: flex;
    justify-content: space-between;
    width: 95%;
}

.my-maps-title {
    color: #144066;
    display: block;
    font-family: Roboto, sans-serif;
    font-size: 1.25rem;
    font-weight: 500;
    line-height: 1.5rem;
}

.my-maps-selection-container {
    align-items: center;
    display: flex;
    justify-content: flex-start;
}

.my-maps-delete {
    background-color: #144066;
    border: none;
    border-radius: 0.5rem;
    color: #FAFEFF;
    font-family: Roboto, sans-serif;
    font-size: 0.875rem;
    margin-left: 1rem;
    outline: none;
    padding: 0.25rem 0.75rem;
    width: 8rem;
}

.my-maps-delete:hover {
    background-color: #FAFEFF;
    color: #144066;
}

.my-maps-delete:active {
    background-color: #FAFEFF;
    border: 0.1rem solid #144066;
    color: #144066;
    padding: 0.15rem 0.65rem;
}

.my-maps-partition-title {
    color: #144066;
    display: block;
    font-family: Roboto, sans-serif;
    font-size: 1.125rem;
    line-height: 1.25rem;
    margin: 1rem 0 0.5rem;
}

.my-maps-no-maps-container {
    color: rgba(20, 64, 102, 0.6);
    font-family: Roboto, sans-serif;
    font-size: 2.5rem;
    font-style: normal;
    font-weight: normal;
    line-height: 2.5rem;
    margin-top: 25%;
    text-align: center;
    width: 100%;
}

.my-maps-track-horizontal {
    background-color: transparent;
    left: 0.625rem;
    right: 0.625rem;
    bottom: 0.625rem;
}

.my-maps-track-vertical {
    background-color: transparent;
    top: 0.625rem;
    bottom: 0.625rem;
    height: 95%;
    right: 0;
}

.my-maps-thumb-horizontal, .my-maps-thumb-vertical {
    background-color: #9CC6EA;
    border-radius: 0.1875rem;
}

.map-preview {
    border: #144066 solid thin;
    border-radius: 0.5rem;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    text-align: center;
}

.map-preview-header {
    align-items: flex-start;
    background-color: rgba(196, 222, 245, 0.8); /*#C4DEF5 with 0.8 transparency*/
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
    color: #144066;
    display: flex;
    font-family: Roboto, sans-serif;
    font-size: 0.875rem;
    justify-content: space-between;
    padding: 0.25rem 0.5rem;
}

.map-preview-delete {
    background-color: transparent;
    background-image: url(../../../studio/images/extraWindow/cross.svg);
    background-size: contain;
    border: none;
    cursor: pointer;
    height: 0.75rem;
    margin-top: 0.125rem;
    min-width: 0.75rem;
    padding-top: 0;
    width: 0.75rem;
}

.modal-background-container {
    display: grid;
    grid-template-areas: "title" "radio" "prevent" "apply";
    grid-template-rows: 4.25rem 3.75rem 3.75rem 4.25rem;
}

.modal-background-title {
    grid-area: title;
}

.modal-background-radio-group {
    grid-area: radio;
    margin-left: 0.625rem;
}

.modal-background-check-prevent {
    align-items: center;
    display: flex;
    grid-area: prevent;
    justify-content: flex-start;
    margin-left: 0.625rem;
}


.survey-answer-checkbox-hidden-std {
    opacity: 0;
    position: absolute;
    z-index: -1;
}

.survey-answer-checkbox-label {
    color: #144066;
    font-family: Roboto, sans-serif;
    font-size: 1rem;
    font-style: normal;
    font-weight: normal;
}

.survey-answer-checkbox-hidden-std+.survey-answer-checkbox-label::before {
    background-color: #9CC6EA;
    border-radius: 20%;
    content: '';
    display: inline-block;
    height: 1rem;
    margin-bottom: -0.1rem;
    margin-right: 0.7rem;
    width: 1rem;
}

.survey-answer-checkbox-hidden-std:checked+.survey-answer-checkbox-label::before {
    background-color: #144066;
    border: 0.2rem solid #9CC6EA;
    height: 0.6rem;
    width: 0.6rem;
}

.modal-background-apply {
    align-items: center;
    display: flex;
    grid-area: apply;
    height: 2.5rem;
    justify-content: center;
    margin-bottom: 1.25rem;
}


.modal-background-textbox {
    background-color: #C4DEF5;
    border: none;
    color: #144066;
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 1rem;
    height: 1.25rem;
    line-height: 2.25rem;
    width: 4rem;
}

.modal-background-textbox::-webkit-outer-spin-button, /*hide arrows in webkit-based*/
.modal-background-textbox::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.modal-background-textbox[type=number] { /*hide arrows in firefox*/
    -moz-appearance: textfield;
}

.modal-background-textbox:focus {
    border-bottom: 0.0625rem solid #144066;
}

.modal-background-textbox::placeholder {
    color: rgba(20, 64, 102, 0.6);
}

.modal-background-textbox:focus::placeholder {
    color: rgba(20, 64, 102, 0.75);
}

.postcard-title {
    color: #144066;
    font-family: Rubik, sans-serif;
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.5rem;
    margin-bottom: 0.875rem;
    margin-top: 1.25rem;
    text-align: center;
}

.postcard-sharing-container {
    align-items: center;
    bottom: 1rem;
    display: flex;
    justify-content: space-between;
    position: absolute;
    right: 1.25rem;
}

.postcard-share-on {
    color: #144066;
    font-family: Rubik, sans-serif;
    font-size: 1rem;
    line-height: 1.25rem;
    margin-right: 0.25rem;
}

.postcard-share-wrapper {
    background-color: #C4DEF5;
    border: 0.125rem solid #C4DEF5;
    border-radius: 0.375rem;
    height: 1.5rem;
    margin: 0 0.09375rem;
    padding: 0.125rem;
    width: 1.5rem;
}

.postcard-share-wrapper:first-child {
    margin-left: 0;
}

.postcard-share-wrapper:hover {
    border: 0.125rem solid #144066;
}

.postcard-editor-container {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.postcard-tab-container {
    align-items: center;
    background-color: #C4DEF5;
    border-radius: 0 0.625rem 0.625rem 0.625rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 41.0625rem;
    padding: 0.5rem 0.625rem 0.625rem;
    width: 53.75rem;
}

.postcard-container {
    align-items: flex-end;
    background-color: #FAFEFF;
    border-radius: 0.625rem;
    display: flex;
    height: 30rem;
    justify-content: flex-end;
    padding: 0.625rem;
    position: relative;
    width: calc(100% - 1.25rem);
}

.postcard-logo {
    position: absolute;
}

.postcard-link-to-studio, .postcard-part-title {
    color: #144066;
    font-family: Roboto, sans-serif;
    font-weight: 600;
    font-size: 0.875rem;
    line-height: 1.5rem;
}

.postcard-link-to-studio {
    position: absolute;
    top: 0.5rem;
    left: 0.625rem;
}

.postcard-map-statistics {
    border-radius: 0.625rem;
    display: flex;
    font-family: Rubik, sans-serif;
    justify-content: center;
}

.postcard-statistics-table-horizontal {
    border-right: 1px solid #FAFEFF;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: 1.625rem 2.3125rem 0.6875rem;
    grid-template-areas: "first-percent second-percent third-percent"
                         "first-explanation second-explanation third-explanation"
                         "footnote footnote footnote";
    padding: 0.6875rem 0.875rem 0.1875rem;
    height: calc(100% - 0.875rem);
    width: calc(50% - 1.75rem);
}

.postcard-statistics-table-vertical {
    border-bottom: 1px solid #FAFEFF;
    display: grid;
    grid-template-columns: 60px 117px;
    grid-template-rows: 3rem 3rem 2.5625rem 1.375rem;
    grid-template-areas: "first-percent first-explanation"
                         "second-percent second-explanation"
                         "third-percent third-explanation"
                         "footnote footnote";
    grid-column-gap: 0.625rem;
    padding: 0.125rem 0 1.5rem;
    height: 13.125rem;
    width: 100%;
}

.postcard-user-text-container-horizontal, .postcard-user-text-container-vertical {
    font-weight: 500;
    font-size: 1.375rem;
    overflow-wrap: break-word;
    overflow: hidden;
}

.postcard-user-text-container-horizontal {
    height: 92%;
    margin: 0 2rem 0;
    width: 16rem;
}

.postcard-user-text-container-vertical {
    height: 7.2rem;
    margin: 1.25rem 0 1.25rem;
    width: 100%;
}

.postcard-qr-container {
    height: 5rem;
    width: 5rem;
}

.postcard-percent-text {
    font-weight: 500;
    font-size: 1.375rem;
    line-height: 1.625rem;
}

.postcard-explanation-text {
    font-size: 0.8125rem;
    line-height: 0.6875rem;
}

.postcard-footnote {
    display: inline-block;
    font-size: 0.75rem;
    grid-column: 1 / 4;
    grid-area: footnote;
    line-height: 0.6875rem;
}

.first-percent {
    grid-area: first-percent;
}

.second-percent {
    grid-area: second-percent;
}

.third-percent {
    grid-area: third-percent;
}

.first-explanation {
    grid-area: first-explanation;
}

.second-explanation {
    grid-area: second-explanation;
}

.third-explanation {
    grid-area: third-explanation;
}

.postcard-configs-container {
    display: flex;
    margin-top: 0.625rem;
}

.postcard-config-color-container, .postcard-config-text-container {
    background-color: #FAFEFF;
    border-radius: 0.625rem;
    height: 8.0625rem;
    padding: 0.5rem 0.75rem 0.625rem;
    width: 25.0625rem;
}

.postcard-config-text-container {
    display: grid;
    grid-template-columns: 17.6875rem 7.375rem;
    grid-template-rows: 2rem 6.25rem;
    margin-left: 0.625rem;
}

.postcard-config-container:not(:last-child) {
    margin-right: 0.625rem;
}

.postcard-config-colors-container {
    display: flex;
    margin-top: 0.3125rem;
    width: 100%;
}

.postcard-config-color {
    border-radius: 0.3125rem;
    cursor: pointer;
    height: 1.875rem;
    margin-right: 0.625rem;
    width: 1.875rem;
}

.postcard-config-color-active {
    align-items: center;
    border-radius: 0.3125rem;
    border: 0.0625rem solid #144066;
    display: flex;
    height: 1.875rem;
    justify-content: center;
    margin-right: 0.625rem;
    width: 1.875rem;
}

.postcard-config-color-small {
    border-radius: 0.3125rem;
    height: 1rem;
    width: 1rem;
}

.postcard-config-color-params-container {
    display: flex;
    margin-top: 1.25rem;
    width: 100%;
}

.postcard-config-color-chosen-container {
    align-items: center;
    background-color: #C4DEF5;
    border-radius: 0.5rem;
    display: flex;
    height: 1.875rem;
    justify-content: center;
    margin-right: 0.625rem;
    width: 5.625rem;
}

.postcard-config-color-chosen {
    border-radius: 0.25rem;
    height: 1.125rem;
    width: 1.125rem;
}

.postcard-config-color-chosen-name {
    color: #144066;
    font-family: Roboto, sans-serif;
    font-size: 0.875rem;
    line-height: 1.5rem;
    margin-left: 0.375rem;
    opacity: 0.6;
}

.postcard-config-gradient-angle-container {
    background-color: #C4DEF5;
    border-radius: 0.625rem;
    display: flex;
    flex-direction: column;
    height: 1.875rem;
    margin-right: 0.625rem;
    padding: 0.625rem;
    width: 10.625rem;
}

.postcard-config-gradient-angle-title {
    color: #144066;
    font-family: Roboto, sans-serif;
    font-size: 0.875rem;
    line-height: 1rem;
}

.postcard-config-user-text {
    background-color: #C4DEF5;
    border: none;
    border-radius: 0.625rem;
    color: #144066;
    height: 6rem;
    font-family: Roboto, sans-serif;
    font-size: 0.875rem;
    line-height: 1.5rem;
    padding: 0.125rem 0.625rem;
    resize: none;
    width: 15.75rem;
}

.postcard-config-user-text::placeholder {
    color: #14406699;
}

.postcard-config-text-color {
    background-color: #C4DEF5;
    border: none;
    border-radius: 0.5rem;
    color: #144066;
    height: 1.625rem;
    outline: none;
    padding: 0.125rem 0.625rem;
    width: 6.1875rem;
}

.sharing-single-select {
    background-color: #9CC6EA;
    border: 0.125rem solid #9CC6EA;
    border-radius: 0.625rem;
    color: #144066;
    font-family: Roboto, sans-serif;
    width: 12.5rem;
}

.sharing-single-select div {
    color: inherit;
    padding: 0;
}

.sharing-single-select div[class$="-control"] {
    background-color: transparent;
    background-repeat: repeat;
    border: none;
    box-sizing: border-box;
    box-shadow: none;
    border-radius: 0.625rem;
    color: #144066;
    font-family: Roboto, sans-serif;
    font-size: 1rem;
    line-height: 1.5rem;
    min-height: auto;
    padding: 0.25rem 0 0.25rem 0.625rem;
    width: 100%;
}

.sharing-single-select div[class*="Input"] {
    grid-area: 1/3/2/3;
    margin: 0 0 0 0.125rem;
}

.sharing-single-select div[class*="ValueContainer"] {
    flex-wrap: nowrap;
}

.sharing-single-select div[class*="input-container"] {
    grid-area: 1/3/2/3;
}

.sharing-single-select div[class*="control--is-focused"] {
    background-color: #C4DEF5;
}

.sharing-single-select div[class$="menu"] {
    background-color: #C4DEF5;
    border: 0.0625rem solid #144066;
    border-radius: 0.625rem;
    line-height: 2rem;
    min-height: 2rem;
}

.sharing-single-select div[class*="menu-list"] {
    background-color: #C4DEF5;
    margin: 0;
}

.sharing-single-select div[class*="placeholder"] {
    opacity: 0.6;
}

.sharing-single-select div[class*="value-container--has-value"] {
    flex-wrap: nowrap;
    opacity: 1;
}

.sharing-single-select div[class*="option"] {
    border-radius: 0.625rem;
    color: #144066;
    font-family: Roboto, sans-serif;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5rem;
    background-color: #C4DEF5;
    padding: 0.3125rem 0.625rem;
}

.sharing-single-select div[class*="option"]:first-child {
    padding-top: 0.625rem;
}

.sharing-single-select div[class*="option"]:last-child {
    padding-bottom: 0.625rem;
}

.sharing-single-select div[class*="clear-indicator"] {
    margin-right: 0.5rem;
}

.modal-sharing-option>*:first-child::before, .modal-sharing-remove-option>*:first-child::before {
    content: "";
    margin-right: calc(0.375rem + 16px);
}

.modal-sharing-selected-option>*:first-child::before {
    content: url("../../../studio/components/modalWindows/sharing/images/tick.svg");
    margin-right: 0.375rem;
}

.sharing-single-select .modal-sharing-remove-option div[class*="option"] {
    border-top: 0.0625rem solid #144066;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}