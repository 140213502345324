.page-404-container {
    align-items: center;
    background-color: #FAFEFF;
    color: black;
    display: flex;
    flex-direction: column;
    height: calc(100% - 23.75rem);
    justify-content: center;
    padding-top: 3.75rem;
    width: 100%;
}

.page-404-title {
    color: #144066;
    font-family: Roboto, sans-serif;
    font-size: 1.5rem;
    font-weight: 500;
    line-height: 1.75rem;
    text-align: center;
}

.page-404-text, .page-unsupported-device-text {
    color: #0F1D23;
    font-family: Roboto, sans-serif;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5rem;
    margin: 0.25rem 0;
}

.page-404-link {
    color: #0F1D23;
    cursor: pointer;
    font-family: Roboto, sans-serif;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5rem;
    text-decoration: underline;
}

.loading-logo-animation {
    animation-delay: 0.5s;
    animation-duration: 3s;
    animation-iteration-count: infinite;
    animation-name: loading-logo;
}

@keyframes loading-logo {
    from {
      transform: scale(1, 1);
    }
    25% {
        transform: scale(1, 0);
    }
    50% {
        transform: scale(1, -1);
    }
    75% {
        transform: scale(1, 0);
    }
    to {
        transform: scale(1, 1);
    }
}

.tick-title {
    color: #0F1D23;
    font-family: Roboto, sans-serif;
    font-size: 1.125rem;
    font-weight: 500;
    line-height: 1.5rem;
}

.tick-text {
    color: #0F1D23;
    font-family: Roboto, sans-serif;
    font-size: 1rem;
    font-weight: 300;
    line-height: 1.5rem;
    margin: 0.25rem 0;
}

.tick-link {
    color: #144066;
    cursor: pointer;
    font-family: Roboto, sans-serif;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5rem;
    text-decoration: underline;
}

.page-unsupported-device {
    align-items: center;
    background-color: #FAFEFF;
    color: black;
    display: flex;
    flex-direction: column;
    height: 100vh;
    justify-content: center;
    width: 100vw;
}

.page-unsupported-device-text {
    margin: 0.25rem 10vw;
    text-align: center;
}