.project-dfd-canvas-container {
    background-color: white;
    border-radius: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: calc(100vh - 13rem);
    width: calc(100vw - 32rem);
    padding: 1rem 1rem 0;
}

.project-dfd-canvas-grid {
    display: grid;
    grid-template-columns: 15rem 1fr;
    height: 100%;
}

.project-node-menu-container {
    display: flex;
    flex-direction: column;
    overflow: auto;
}

.project-node-menu-items-container {
    display: flex;
    flex-direction: column;
    overflow: auto;
    padding-right: 1rem;
}

.project-dfd-menu-item {
    border: 0.1rem solid black;
    border-radius: 0.5rem;
    cursor: grabbing;
    margin: 0.5rem 0.5rem;
    padding: 0.5rem 1rem;
    text-align: center;
}

.project-dfd-menu-item:hover {
    background-color: #C4DEF5;
}

.project-node-menu-delimiter {
    background-color: #C4DEF5;
    border-radius: 0.5rem;
    display: block;
    margin: 0.5rem 0;
    padding: 0.5rem 0.5rem;
    position: sticky;
    top: 0;
}

.project-dfd-menu-item-hint-container {
    display: flex;
}

.project-dfd-menu-item-hint-container div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.project-dfd-menu-item-hint-container p {
    margin-top: 0;
    margin-bottom: 0;
}

.project-dfd-menu-item-hint-container p:not(:last-child):not(:first-child) {
    margin-right: 0.5rem;
    margin-left: 0.5rem;
}

.project-dfd-source-node {
    padding: 2rem;
    border: 0.1rem solid black;
    border-radius: 50% 1rem 1rem 50%;
}

.project-dfd-intermediate-node {
    padding: 2rem;
    border: 0.1rem solid black;
    border-radius: 1rem 1rem;
}

.project-dfd-final-node {
    align-items: flex-start;
    border: 0.1rem solid black;
    border-radius: 1rem 50% 50% 1rem;
    display: flex;
    flex-direction: column;
    padding: 2rem;
}

.project-dfd-source-node *, .project-dfd-intermediate-node *, .project-dfd-final-node * {
    font-size: 0.75rem;
}

.project-dfd-source-node-title, .project-dfd-intermediate-node-title, .project-dfd-final-node-title {
    top: -1.75rem;
    position: relative;
}

.project-dfd-final-node-title {
    left: -1.5rem;
}

.project-dfd-source-node-title {
    right: -1.5rem;
}

.project-dfd-node-handle-name {
    font-size: 0.75rem;
    position: absolute;
    white-space: nowrap;
}

.react-flow {
    background-color: white;
    border: 0.1rem solid silver;
    border-radius: 0.5rem;
    height: 100%;
}

.project-dfd-visualization-container {
    display: flex;
    flex-direction: column;
}

.project-dfd-return-to-pipeline {
    background-color: #144066;
    border: 0.1rem solid #144066;
    border-radius: 0.625rem;
    color: #FAFEFF;
    cursor: pointer;
    margin-bottom: 1rem;
    padding: 0.25rem 1rem;
}

.project-dfd-return-to-pipeline:hover {
    background-color: #C4DEF5;
    color: #144066;
}

.project-dfd-return-to-pipeline:active {
    background-color: #FAFEFF;
}

.project-node-menu-run-pipeline {
    border: none;
    border-radius: 0.5rem;
    margin: 1rem 0.5rem;
    padding: 0.5rem;
}

.project-node-menu-run-pipeline:hover, .project-node-menu-filter-reset:hover {
    background-color: #C4DEF5;
}

.project-node-menu-run-pipeline:active, .project-node-menu-filter-reset:active {
    background-color: #9CC6EA;
    color: #FAFEFF;
}

.project-node-menu-progress-bar {
    font-size: 0.75rem;
    margin: 0.25rem;
}

.project-node-menu-filter-container {
    border-bottom: 0.1rem solid silver;
    display: grid;
    grid-template-columns: 1fr 2rem 1fr;
    grid-template-rows: 1fr 2rem;
    grid-row-gap: 0.5rem;
    padding-bottom: 1rem;
    padding-right: 1rem;
    text-align: center;
}

.project-node-menu-filter-reset {
    border: none;
    border-radius: 0.5rem;
    grid-column: 1 / 4;
}
