.control-checkbox-wrapper {
    border-radius: 0.625rem;
    display: flex;
    justify-content: center;
    margin-bottom: 1rem;
    width: 20.125rem;
    user-select: none;
}

.control-checkbox-hidden-input {
    opacity: 0;
    position: absolute;
    z-index: -1;
}

.control-checkbox-label {
    color: #144066;
    cursor: pointer;
    font-family: Roboto, sans-serif;
    font-size: 1rem;
    font-style: normal;
    font-weight: normal;
    text-align: center;
}

.control-checkbox-hidden-input+.control-checkbox-label::before {
    background-color: #9CC6EA;
    border-radius: 0.25rem;
    content: '';
    display: inline-block;
    height: 1rem;
    margin-bottom: -0.1rem;
    margin-right: 0.7rem;
    width: 1rem;
}

.control-checkbox-hidden-input:checked+.control-checkbox-label::before {
    background-color: #144066;
    border: 0.2rem solid #9CC6EA;
    height: 0.6rem;
    width: 0.6rem;
}

.control-checkbox-wrapper a {
    color: #144066;
    text-decoration: underline;
}