.floating-menu-md-editor, .floating-menu-md-editor-disabled {
    align-items: center;
    border: 0.125rem solid #C4DEF5;
    border-radius: 0.625rem;
    display: flex;
    flex-direction: column;
    width: 37rem;
}

.floating-menu-md-editor-disabled {
    opacity: 0.3;
    pointer-events: none;
}
