.hint-mode-beacon {
    border-radius: 0;
    color: #144066;
    cursor: help;
    display: inline-block;
    font-size: 1.5rem;
    font-weight: 900;
    height: 2rem;
    pointer-events: all;
    width: 1rem;
    z-index: 900;
}

/* .react-tooltip-lite-arrow {
       color: #144066;
} */

.tooltip-content {
    animation-duration: 1s;
    animation-fill-mode: forwards;
    animation-name: show;
    background: #C4DEF5;
    border: 0.0625rem solid #144066;
    border-radius: 0.625rem;
    color: #144066;
    display: block;
    max-width: 21.875rem;
    padding: 0.625rem;
    pointer-events: none;
    white-space: normal;
    z-index: 1200;
}

.no-pointer-events-tooltip .react-tooltip-lite {
    pointer-events: none;
}
