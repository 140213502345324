.licenses-list-body {
    background-color: #FAFEFF;
    color: #122E73;
    display: flex;
    flex-direction: column;
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.25rem;
    min-height: 70vh;
    padding: 4rem 7rem 0;
}

.licenses-list-body ul {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 1.125rem;
    justify-content: flex-start;
    line-height: 2rem;
    list-style-type: none;
}

.licenses-list-body li {
    cursor: pointer;
    display: inline;
}

.license-body {
    background-color: #FAFEFF;
    color: #122E73;
    display: flex;
    flex-direction: column;
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.25rem;
    padding: 1rem 3rem 0;
}

.license-body button {
    background-color: transparent;
    border: none;
    color: #144066;
    cursor: pointer;
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 1.75rem;
    line-height: 2.25rem;
    height: 4rem;
    outline: none;
    text-align: start;
    width: 20rem;
}

.license-container, .license-container-digits-only {
    margin: 2rem 25rem 3rem 5rem;
}

.license-container ol {
    list-style-type: lower-latin;
}

.license-container ol ol {
    list-style-type: decimal;
}

.license-container ol ol ol {
    list-style-type: upper-latin;
}

.license-container ol ol ol ol {
    list-style-type: lower-roman;
}

.license-container-digits-only ol {
    list-style-type: decimal;
}