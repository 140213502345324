input[type=range] {
    -webkit-appearance: none;
    background-color: #C4DEF5;
    height: 1.25rem;
    margin: 0.125rem 0;
}

input[type=range]:focus {
    outline: none;
}

input[type=range]::-webkit-slider-runnable-track {
    background: #FAFEFF;
    border-radius: 0.625rem;
    cursor: pointer;
    height: 0.375rem;
    width: 8rem;
}

input[type=range]:focus::-webkit-slider-runnable-track {
    background: #FAFEFF;
}

input[type=range]::-moz-range-track {
    background: #FAFEFF;
    border-radius: 0.625rem;
    cursor: pointer;
    height: 0.375rem;
}

input[type=range]::-ms-track {
    background: transparent;
    border-color: transparent;
    color: transparent;
    cursor: pointer;
    height: 0.375rem;
    width: 8rem;
}

input[type=range]::-webkit-slider-thumb {
    -webkit-appearance: none;
    border-radius: 0.625rem;
    background: #144066;
    cursor: pointer;
    height: 0.875rem;
    margin-top: -0.25rem;
    width: 0.375rem;
}

input[type=range]::-moz-range-thumb {
    background: #144066;
    border-radius: 0.375rem;
    cursor: pointer;
    height: 0.875rem;
    width: 0.375rem;
}

input[type=range]::-ms-fill-lower {
    background: #FAFEFF;
    border: 0.0625rem solid #144066;
    border-radius: 3.125rem;
    box-shadow: 0 0 0 #000000;
}

input[type=range]::-ms-fill-upper {
    background: #FAFEFF;
    border: 0.0625rem solid #144066;
    border-radius: 3.125rem;
    box-shadow: 0 0 0 #000000;
}

input[type=range]::-ms-thumb {
    background: #144066;
    box-shadow: 0.0625rem 0.0625rem 0.0625rem #144066;
    border: 0.0625rem solid #144066;
    border-radius: 0.375rem;
    cursor: pointer;
    height: 1.5rem;
    margin-top: 0.0625rem;
    width: 2.1875rem;
}

input[type=range]:focus::-ms-fill-lower {
    background: #FAFEFF;
}

input[type=range]:focus::-ms-fill-upper {
    background: #FAFEFF;
}

.floating-menu-slider-title {
    color: #074479;
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 0.875rem;
    line-height: 1rem;
    margin-top: 0;
    min-width: 1.875rem;
}

.floating-menu-slider-container {
    background-color: transparent;
    border: 0;
    border-radius: 0.625rem;
    display: flex;
    flex-direction: column;
    padding: 0.625rem;
}

.floating-menu-slider {
    width: 8rem;
}
