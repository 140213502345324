/*
    чтобы поменять размеры меню, надо менять:
    floating-menu.height: \d+\w+;
    floating-menu.width: \d+\w+;
*/

.floating-menu {
    background: #C4DEF5;
    border-radius: 0.625rem;
    padding-bottom: 0.5rem;
    position: fixed;
    user-select: none;
    width: 6.75rem;
    z-index: 150;
}

.floating-menu-background {
    background: none;
    height: 100%;
    opacity: 0.5;
    position: absolute;
    width: 100%;
    z-index: 150;
}

.floating-menu-header {
    cursor: move;
    height: 1.3125rem;
    width: 6.25rem;
}

.floating-menu-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    padding: 0 0.25rem;
    width: calc(100% - 0.5rem);
}

.floating-menu-divider {
    background: #143D66;
    border-radius: 0.25rem;
    height: 0.125rem;
    margin: 0.25rem 0.625rem;
    opacity: 0.4;
    width: 5.5rem;
}

.floating-menu-divider:last-child, .floating-menu-divider:nth-child(2) {
    display: none;
}

.floating-menu-submenu-title {
    color: #143D66;
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 0.875rem;
    height: 0.75rem;
    line-height: 1rem;
    margin: -0.1875rem 0 0.25rem 0.625rem;
}
